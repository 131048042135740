import {
  Avatar,
  Box,
  Circle,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage, useTimeout } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { CertifiedCheckIcon } from "adminComponents/atoms/CertifiedCheckIcon";
import { ClampedHeading } from "adminComponents/atoms/ClampedHeading";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon, IconType } from "adminComponents/atoms/Icon";
import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { RateButton } from "adminComponents/atoms/RateButton";
import { SubmarkLogo } from "adminComponents/atoms/SubmarkLogo";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { colors } from "adminComponents/theme/colors";
import { pxToRem, useStringifyList } from "adminComponents/utils";
import { isPremiumItem } from "adminComponents/utils/isPremiumItem";
import { usePracticeSetStandardLabels } from "adminComponents/utils/usePracticeSetStandardLabels";
import {
  APPCUES_LIVE_PRACTICE_ENCOURAGEMENT_FLOW_ID,
  localStoreHideLivePracticeEncouragementUntilKeyName,
} from "links/lib/constants";
import { useAuth } from "links/lib/features/auth";
import { IPracticeSet, IPracticeSetItem } from "links/lib/types";

import CoverImageBlob from "../CoverImageBlob";
import {
  PracticeSetQuestionCard,
  PracticeSetQuestionCardType,
} from "../PracticeSetQuestionCard";

export interface IPracticeSetPreviewProps {
  practiceSet?: IPracticeSet;
  items: IPracticeSetItem[];
  personalRating?: number;
  handlePractice: () => void;
  handleDetails?: () => void;
  handleCopy?: (practiceSet: IPracticeSet) => void;
  handlePreview?: () => void;
  isCopyLoading?: boolean;
  isSetCopied?: boolean;
  handleAssign?: () => void;
  handleRate?: (rating: number) => void;
  onAssign?: void;
  isGuest: boolean;
  hasStartedLivePractice?: boolean;
}

export const PracticeSetPreview: React.FC<IPracticeSetPreviewProps> = ({
  practiceSet,
  items,
  personalRating,
  handlePractice,
  handleDetails,
  handleCopy,
  isCopyLoading,
  isSetCopied,
  handleRate,
  handleAssign,
  handlePreview,
  isGuest,
  hasStartedLivePractice,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { hasNoPremiumAccess, authUser } = useAuth();
  const isMobileAppCueSize = useBreakpointValue({ base: true, lg: false });
  const [
    localStoreHideLivePracticeEncouragementUntil,
    setLocalStoreHideLivePracticeEncouragementUntil,
  ] = useLocalStorage<string | undefined>(
    localStoreHideLivePracticeEncouragementUntilKeyName,
    undefined
  );

  const allItemsArePremium = items.length > 0 && items.every(isPremiumItem);
  const premiumActionsDisallowed =
    hasNoPremiumAccess && (practiceSet?.is_premium || allItemsArePremium);

  // eslint-disable-next-line
  const _handleCopy = useCallback(
    debounce((practiceSet: IPracticeSet) => handleCopy?.(practiceSet), 1000, {
      leading: true,
      trailing: false,
    }),
    [handleCopy]
  );

  const [showLivePracticeEncouragementTimeout] = useTimeout(5000);
  const timeoutOver = showLivePracticeEncouragementTimeout();
  useEffect(() => {
    if (isMobileAppCueSize) return;
    if (localStoreHideLivePracticeEncouragementUntil) return;
    if (hasStartedLivePractice) return;
    if (!timeoutOver) return;
    const hideLivePracticeEncouragementUntil =
      localStoreHideLivePracticeEncouragementUntil
        ? moment(localStoreHideLivePracticeEncouragementUntil)
        : undefined;
    if (
      hideLivePracticeEncouragementUntil &&
      moment().isBefore(hideLivePracticeEncouragementUntil)
    ) {
      return;
    }

    window.Appcues?.show(APPCUES_LIVE_PRACTICE_ENCOURAGEMENT_FLOW_ID);
    setLocalStoreHideLivePracticeEncouragementUntil(
      moment().add(1, "days").toString()
    );
  }, [
    hasStartedLivePractice,
    isMobileAppCueSize,
    localStoreHideLivePracticeEncouragementUntil,
    setLocalStoreHideLivePracticeEncouragementUntil,
    timeoutOver,
  ]);

  const menuItems: Array<{
    label: string;
    icon: IconType;
    description: string;
    isDisabled: boolean;
    handleClick: React.MouseEventHandler<HTMLButtonElement>;
  }> = [
    {
      label: t("practiceSetPreview.livePractice"),
      description: t("practiceSetPreview.livePracticeDescription"),
      icon: "play_outlined",
      isDisabled: false,
      handleClick: handlePractice,
    },
  ];

  if (!practiceSet?.is_how_to_play && !practiceSet?.is_digital_citizenship) {
    menuItems.push({
      label: t("practiceSetPreview.assignToClassroom"),
      description: t("practiceSetPreview.assignToClassroomDescription"),
      icon: "person_add_outlined",
      isDisabled: false,
      handleClick: () => handleAssign?.(),
    });
    menuItems.push({
      label: isGuest
        ? t("practiceSetPreview.copyAndEdit")
        : t("practiceSetPreview.copytoMyLibrary"),
      description: t("practiceSetPreview.copyAndEditDescription"),
      icon: "add_circle_outlined",
      isDisabled: isCopyLoading || isSetCopied || false,
      handleClick: () => (practiceSet ? _handleCopy(practiceSet) : undefined),
    });
  }

  const standardLabels = usePracticeSetStandardLabels(practiceSet);
  const standardsString = useStringifyList(standardLabels, 5);

  return (
    <VStack
      w="full"
      alignItems="flex-start"
      justifyContent="flex-start"
      px={["admin.flyout.mobileXPadding", null, "admin.flyout.desktopXPadding"]}
      pb={pxToRem(20)}
      pt={pxToRem(15)}
      spacing={pxToRem(30)}
    >
      {practiceSet && (
        <>
          {/* Practice Set Rating */}
          <Box position="absolute" top={pxToRem(22)} right={pxToRem(64)}>
            {practiceSet.rating && handleRate && (
              <RateButton
                rating={practiceSet.rating}
                personalRating={personalRating || 0}
                handleRate={handleRate}
                upvoteAriaLabel={t(
                  "practiceSetInformationCardPublic.upvoteAriaLabel"
                )}
                downvoteAriaLabel={t(
                  "practiceSetInformationCardPublic.downvoteAriaLabel"
                )}
              />
            )}
            {isGuest &&
              practiceSet.rating &&
              practiceSet.rating.total_count_positive > 0 && (
                <HStack spacing={pxToRem(3)} mt={pxToRem(6)} mr={pxToRem(16)}>
                  <Icon
                    w={pxToRem(24)}
                    h={pxToRem(24)}
                    icon="thumb_up_outlined"
                  />
                  <Text variant="adminP2Bold">
                    {practiceSet.rating.total_count_positive.toLocaleString()}
                  </Text>
                </HStack>
              )}
          </Box>

          <HStack
            w="full"
            alignItems="center"
            justifyContent="flex-start"
            spacing={pxToRem(6)}
            gap={pxToRem(10)}
          >
            <CoverImageBlob
              data-role="coverImage"
              backgroundColorScheme={practiceSet.cover_image_bg_color_scheme}
              backgroundPattern={practiceSet.cover_image_bg_pattern}
              icon={practiceSet.cover_image_icon}
              boxSize={100}
            />
            {practiceSet.is_certified && (
              <Flex
                alignItems="center"
                borderRadius="full"
                bgColor="lilac.03"
                gap={pxToRem(8)}
                p={pxToRem(10)}
                px={pxToRem(15)}
              >
                <Text color="primary.warm-white" variant="adminP2">
                  {t("common.certified")}
                </Text>
                <CertifiedCheckIcon boxSize={4} />
              </Flex>
            )}
            {practiceSet.is_premium && <PremiumIcon size={36} />}
          </HStack>

          <VStack
            w="full"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={pxToRem(15)}
          >
            <ClampedHeading
              noOfLines={3}
              headingAs="h3"
              headingVariant="adminH3Bold"
            >
              {practiceSet.title}
            </ClampedHeading>
            <HStack spacing={pxToRem(5)}>
              {practiceSet?.author_is_internal_content_specialist && (
                <Circle
                  bgColor={colors["pear-practice"]["blue-skies"]}
                  size={pxToRem(24)}
                  p={pxToRem(4)}
                  overflow="hidden"
                >
                  <SubmarkLogo />
                </Circle>
              )}
              {!practiceSet?.author_is_internal_content_specialist &&
                practiceSet?.author_profile_image_url && (
                  <Avatar
                    size="sm"
                    src={practiceSet?.author_profile_image_url}
                    name={practiceSet?.author_name}
                  />
                )}
              <Text variant="adminP1" color="primary.dark-gray">
                {t("common.by")}
              </Text>
              {practiceSet?.author_custom_url_code &&
              !practiceSet?.author_profile_is_private ? (
                <TextLink
                  size="lg"
                  height={pxToRem(24)}
                  navigationLink={{
                    to: isGuest
                      ? `/profiles/${practiceSet?.author_custom_url_code}`
                      : `/t/profiles/${practiceSet?.author_custom_url_code}`,
                    label: practiceSet?.author_name || "",
                    isExternal: false,
                  }}
                >
                  {practiceSet?.author_name}
                </TextLink>
              ) : (
                <Text color="primary.dark-gray">
                  {practiceSet?.author_name}
                </Text>
              )}
            </HStack>
            <VStack spacing={pxToRem(4)} alignItems="flex-start">
              <HStack>
                <Text variant="adminP2Bold" sx={{ textWrap: "nowrap" }}>
                  {t("practiceSetPreview.questionCount", {
                    count: items.length,
                  })}
                </Text>
                {practiceSet.grade_levels.length > 0 && (
                  <Text variant="adminP2Bold">&bull;</Text>
                )}
                <Text variant="adminP2Bold" noOfLines={1}>
                  {practiceSet.grade_levels
                    .map((gradeLevel) => gradeLevel.grade_level.grade_level)
                    .join(", ")}
                </Text>
              </HStack>
              <Text>{practiceSet?.description}</Text>
            </VStack>
            <ClampedHeading
              headingStyles={{
                color: "primary.dark-gray",
                fontSize: pxToRem(16),
              }}
              noOfLines={2}
              headingAs="h4"
              headingVariant="adminH7"
            >
              {practiceSet.subjects
                .map((subject) => subject.subject.name)
                .join(", ")}
            </ClampedHeading>
            <ClampedHeading
              headingStyles={{
                color: "primary.dark-gray",
                fontSize: pxToRem(16),
              }}
              noOfLines={2}
              headingAs="h4"
              headingVariant="adminH7"
            >
              {standardsString}
            </ClampedHeading>

            <HStack w="full" spacing={pxToRem(10)} alignItems="center">
              <Box w="full">
                <Menu computePositionOnMount variant="adminDropdown">
                  <MenuButton w="full">
                    <Button variant="adminButtonFilled" w="full">
                      <HStack justifyContent="center">
                        <Text>{t("practiceSetPreview.buttonUse")}</Text>
                        <Icon icon={"arrow_drop_down"} />
                      </HStack>
                    </Button>
                  </MenuButton>
                  <MenuList zIndex="dropdown">
                    {isGuest ? (
                      <VStack
                        maxW={pxToRem(340)}
                        p={pxToRem(16)}
                        spacing={pxToRem(16)}
                        alignItems="flex-start"
                      >
                        <Heading as="h4" variant="adminH4">
                          {t("practiceSetPreview.usingPracticeSets")}
                        </Heading>
                        <VStack
                          w="full"
                          alignItems="flex-start"
                          spacing={pxToRem(12)}
                        >
                          {menuItems.map((item, i) => {
                            return (
                              <VStack key={i} spacing={pxToRem(4)}>
                                <HStack w="full" spacing={pxToRem(12)}>
                                  <Icon icon={item.icon} w={pxToRem(24)} />
                                  <Text variant="adminP2Bold" w="full">
                                    {item.label}
                                  </Text>
                                </HStack>
                                <Text variant="adminP2">
                                  {item.description}
                                </Text>
                              </VStack>
                            );
                          })}
                        </VStack>
                        <Button
                          variant="adminButtonFilled"
                          onClick={handlePractice}
                          w="full"
                        >
                          {t("practiceSetPreview.buttonUseThisPracticeSet")}
                        </Button>
                      </VStack>
                    ) : (
                      <>
                        {menuItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            cursor={
                              item.isDisabled || premiumActionsDisallowed
                                ? "not-allowed"
                                : undefined
                            }
                            onClick={
                              item.isDisabled || premiumActionsDisallowed
                                ? undefined
                                : item.handleClick
                            }
                            disabled={
                              item.isDisabled || premiumActionsDisallowed
                            }
                          >
                            <PremiumTooltipRich
                              isDisabled={!premiumActionsDisallowed}
                            >
                              <HStack>
                                <Icon icon={item.icon} />
                                <Text variant="adminP2" w="full">
                                  {item.label}
                                </Text>
                              </HStack>
                            </PremiumTooltipRich>
                          </MenuItem>
                        ))}
                      </>
                    )}
                  </MenuList>
                </Menu>
              </Box>

              {handleDetails && (
                <Box>
                  <Button onClick={handleDetails} variant="adminButtonOutlined">
                    {t("practiceSetPreview.buttonDetails")}
                  </Button>
                </Box>
              )}

              {handlePreview && !isGuest && (
                <PremiumTooltipRich isDisabled={!premiumActionsDisallowed}>
                  <Box>
                    <Button
                      onClick={
                        premiumActionsDisallowed ? undefined : handlePreview
                      }
                      variant="adminButtonOutlined"
                      isDisabled={premiumActionsDisallowed}
                    >
                      <Icon
                        mt={pxToRem(3)}
                        icon="search_preview"
                        aria-label={t("practiceSetPreview.buttonPreview")}
                      />
                    </Button>
                  </Box>
                </PremiumTooltipRich>
              )}
            </HStack>
          </VStack>

          <Divider color="primary.light-gray" />
          <VStack alignItems="flex-start" w="full" spacing={pxToRem(20)}>
            {items.map((item, i) => (
              <PracticeSetQuestionCard
                key={`item-${item.id}`}
                practiceSetItem={item}
                questionNumber={i + 1}
                cardType={PracticeSetQuestionCardType.Answers}
                allowEdit={false}
                allowDuplicate={false}
                allowRemove={false}
                isInPremiumSet={practiceSet.is_premium}
                authUser={authUser}
              />
            ))}
          </VStack>
        </>
      )}
    </VStack>
  );
};
