import { Box, Flex, Image, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Accordion, AccordionData } from "adminComponents/atoms/Accordion";
import { Card } from "adminComponents/atoms/Card";
import { CertifiedCheckIcon } from "adminComponents/atoms/CertifiedCheckIcon";
import { CertifiedTooltip } from "adminComponents/atoms/CertifiedTooltip";
import { Heading } from "adminComponents/atoms/Heading";
import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { Tag, TagColor } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { AnswerDiagram } from "adminComponents/molecules/AnswerDiagram";
import { AnswerMultipleChoice } from "adminComponents/molecules/AnswerMultipleChoice";
import { AnswerTermDefinition } from "adminComponents/molecules/AnswerTermDefinition";
import { AnswerTextResponse } from "adminComponents/molecules/AnswerTextResponse";
import { QuestionDiagram } from "adminComponents/molecules/QuestionDiagram";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { usePearStandardsEnabled } from "adminComponents/utils/usePearStandardsEnabled";
import { IPracticeSetItem, QuestionType } from "links/lib/types";
import { isEmptyTextOrSlate } from "links/lib/util";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

import { AnswerClassify } from "../AnswerClassify";
import { AnswerDraw } from "../AnswerDraw";
import { AnswerNumberResponse } from "../AnswerNumberResponse";
import { AddToSetButton } from "./components/AddToSetButton";

interface IProps {
  practiceSetItem: IPracticeSetItem;
  handleAddToSet?: (item: IPracticeSetItem) => void;
  allowCopy?: boolean;
  showPremiumMarker?: boolean;
}

export const PracticeSetItemAccordion: React.FC<IProps> = ({
  practiceSetItem,
  handleAddToSet,
  allowCopy = true,
  showPremiumMarker = true,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const pearStandardsEnabled = usePearStandardsEnabled();

  const tagColor = useMemo<TagColor>(() => {
    switch (practiceSetItem.question_type) {
      case QuestionType.MultipleChoice:
      case QuestionType.MultipleSelect:
        return "purple";
      case QuestionType.TermDefinition:
        return "blue";
      case QuestionType.TextResponse:
        return "green";
      case QuestionType.Classify:
        return "orange";
      case QuestionType.NumberResponse:
        return "green-grey";
      case QuestionType.Draw:
        return "light-blue";
      default:
        return "default";
    }
  }, [practiceSetItem]);

  const tagLabel = useMemo(() => {
    switch (practiceSetItem.question_type) {
      case QuestionType.MultipleChoice:
      case QuestionType.MultipleSelect:
        return t("common.multipleChoice");
      case QuestionType.TermDefinition:
        return t("common.termDefinition");
      case QuestionType.TextResponse:
        return t("common.textResponse");
      case QuestionType.Diagram:
        return t("common.diagram");
      case QuestionType.Draw:
        return t("common.draw");
      case QuestionType.NumberResponse:
        return t("common.numberResponse");
      case QuestionType.Classify:
        return t("common.classify");
      default:
        return "";
    }
  }, [practiceSetItem, t]);

  const practiceSetItemContent = useMemo(() => {
    if (practiceSetItem.question_type === QuestionType.TermDefinition) {
      return (
        <AnswerTermDefinition
          term={practiceSetItem.term_definition?.term ?? ""}
          alternateSpellings={practiceSetItem.alternate_text_responses}
        />
      );
    }
    if (
      practiceSetItem.question_type === QuestionType.MultipleChoice &&
      practiceSetItem.multiple_choice
    ) {
      return (
        <AnswerMultipleChoice
          choices={practiceSetItem.multiple_choice.choices}
          forceOneColumn
        />
      );
    }
    if (
      practiceSetItem.question_type === QuestionType.MultipleSelect &&
      practiceSetItem.multiple_select
    ) {
      return (
        <AnswerMultipleChoice
          choices={practiceSetItem.multiple_select.choices}
          forceOneColumn
        />
      );
    }
    if (
      practiceSetItem.question_type === QuestionType.Diagram &&
      practiceSetItem.diagram_labels
    ) {
      return (
        <Flex direction="column" gap={{ base: pxToRem(32), md: pxToRem(60) }}>
          <QuestionDiagram
            diagramLabels={practiceSetItem.diagram_labels}
            imageSrc={practiceSetItem.image_url}
            imageAltText={practiceSetItem.image_alt_text}
          />
          <AnswerDiagram
            diagramLabels={practiceSetItem.diagram_labels}
            forceOneColumn
          />
        </Flex>
      );
    }
    if (
      practiceSetItem.question_type === QuestionType.TextResponse &&
      practiceSetItem.text_response
    ) {
      return (
        <AnswerTextResponse
          answer={practiceSetItem.text_response.correct_response}
          alternateAnswers={practiceSetItem.alternate_text_responses}
        />
      );
    }
    if (
      practiceSetItem.question_type === QuestionType.NumberResponse &&
      practiceSetItem.number_response
    ) {
      return (
        <AnswerNumberResponse
          prefix={practiceSetItem.number_response.prefix}
          answer={practiceSetItem.number_response.correct_response}
          suffix={practiceSetItem.number_response.suffix}
          alternateAnswers={practiceSetItem.alternate_text_responses}
        />
      );
    }
    if (
      practiceSetItem.question_type === QuestionType.Draw &&
      practiceSetItem.draw
    ) {
      return <AnswerDraw />;
    }

    if (
      practiceSetItem.question_type === QuestionType.Classify &&
      practiceSetItem.classify
    ) {
      return (
        <AnswerClassify categories={practiceSetItem.classify.categories} />
      );
    }

    return null;
  }, [practiceSetItem]);

  const practiceSetItemImage = useMemo(() => {
    if (!practiceSetItem.image_url) return null;
    return (
      <VStack
        w={pxToRem(96)}
        h={pxToRem(96)}
        flexShrink={0}
        alignItems="center"
      >
        <Image
          src={practiceSetItem.image_url}
          alt={practiceSetItem.image_alt_text}
          maxW="full"
          maxH="full"
          margin="auto"
          borderRadius={pxToRem(8)}
        />
      </VStack>
    );
  }, [practiceSetItem]);

  const practiceSetItemHeader = useMemo(() => {
    let content: string;
    switch (practiceSetItem.question_type) {
      case QuestionType.TermDefinition:
        content = practiceSetItem.term_definition?.definition ?? "";
        break;
      case QuestionType.MultipleChoice:
        content = practiceSetItem.multiple_choice?.prompt ?? "";
        break;
      case QuestionType.MultipleSelect:
        content = practiceSetItem.multiple_select?.prompt ?? "";
        break;
      case QuestionType.Diagram:
        content = practiceSetItem.diagram?.prompt ?? "";
        break;
      case QuestionType.TextResponse:
        content = practiceSetItem.text_response?.prompt ?? "";
        break;
      case QuestionType.NumberResponse:
        content = practiceSetItem.number_response?.prompt ?? "";
        break;
      case QuestionType.Draw:
        content = practiceSetItem.draw?.prompt ?? "";
        break;
      case QuestionType.Classify:
        content = practiceSetItem.classify?.prompt ?? "";
        break;
      default:
        content = "";
    }
    if (!content) return null;
    return <RichTextRenderer content={content} />;
  }, [practiceSetItem]);

  const accordionContent = useMemo<AccordionData[]>(() => {
    const accordionData: AccordionData[] = [];
    if (!isEmptyTextOrSlate(practiceSetItem.feedback)) {
      accordionData.push({
        title: t("common.feedback"),
        content: (
          <Flex gap={[pxToRem(12), null, pxToRem(16)]}>
            {practiceSetItem.feedback_image_url && (
              <Image
                src={practiceSetItem.feedback_image_url}
                alt={practiceSetItem.feedback_image_alt_text}
                w={[pxToRem(60), null, pxToRem(56)]}
                h={[pxToRem(60), null, pxToRem(56)]}
                borderRadius={pxToRem(8)}
                flexShrink={0}
              />
            )}
            <Text variant="adminP3" as="div">
              <RichTextRenderer content={practiceSetItem.feedback} />
            </Text>
          </Flex>
        ),
      });
    }

    if (pearStandardsEnabled) {
      // Pear Standards
      const pearStandards = practiceSetItem.pear_standards || [];
      if (pearStandards.length > 0) {
        accordionData.push({
          title: t("common.standards"),
          content: (
            <Flex gap={[pxToRem(8), null, pxToRem(12)]} flexWrap="wrap">
              {pearStandards.map((standard) => (
                <Tag colorScheme="default" key={standard.id}>
                  {standard.root_label || standard.label}
                </Tag>
              ))}
            </Flex>
          ),
        });
      }
    } else {
      // Legacy Standards
      if (practiceSetItem.standards.length > 0) {
        accordionData.push({
          title: t("common.standards"),
          content: (
            <Flex gap={[pxToRem(8), null, pxToRem(12)]} flexWrap="wrap">
              {practiceSetItem.standards.map((standard) => (
                <Tag colorScheme="default" key={standard.id}>
                  {standard.root_label || standard.label}
                </Tag>
              ))}
            </Flex>
          ),
        });
      }
    }

    return accordionData;
  }, [practiceSetItem, pearStandardsEnabled, t]);

  return (
    <Card
      backgroundColor="primary.white"
      borderColor="primary.tan"
      variant="adminCardMediumBorder"
    >
      <Box mb={[pxToRem(20), null, pxToRem(24)]}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={[pxToRem(22), null, pxToRem(26)]}
          gap={[pxToRem(6), null]}
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={[pxToRem(6), null, pxToRem(8)]}
          >
            {practiceSetItem.is_certified && (
              <CertifiedTooltip>
                <CertifiedCheckIcon inverted />
              </CertifiedTooltip>
            )}
            {showPremiumMarker && practiceSetItem.is_premium && (
              <PremiumIcon size={32} toolTip />
            )}
            <Tag variant="adminSolid" colorScheme={tagColor}>
              {tagLabel}
            </Tag>
          </Flex>
          {!!handleAddToSet && (
            <AddToSetButton
              handleAddToSet={handleAddToSet}
              allowCopy={allowCopy}
              practiceSetItem={practiceSetItem}
            />
          )}
        </Flex>
        <Heading as="p" variant="adminH7" mb={[pxToRem(24), null, pxToRem(28)]}>
          <Flex alignItems="center" gap={pxToRem(24)}>
            {practiceSetItemImage}
            {practiceSetItemHeader}
          </Flex>
        </Heading>
        {practiceSetItemContent}
      </Box>
      <Accordion accordions={accordionContent} />
    </Card>
  );
};
