import { Box } from "@chakra-ui/react";
import { Standard } from "@goguardian/types-psi";
import React, { useMemo } from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { IStandardProps } from "adminComponents/screens/ClassroomAssignmentReportScreen/components/StandardsTab";
import { StandardsTab as AdminStandardsTab } from "adminComponents/screens/ClassroomDetailScreen/components/StandardsTab";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { useFetchClassroomStandardAccuracies } from "links/lib/features/classrooms";
import { useFetchStandards } from "links/lib/features/standards";
import {
  AnalyticsPage,
  IAssignment,
  IClassroom,
  IStandard,
} from "links/lib/types";

interface IStandardsTabProps {
  classroom: IClassroom;
  assignment: IAssignment;
}

export const StandardsTab: React.FC<IStandardsTabProps> = ({
  classroom,
  assignment,
}) => {
  const { hasNoPremiumAccess } = useAuth();
  usePageTrack(
    AnalyticsPage.TeacherDashboard_ClassroomDetail_AssignmentDetail_Standards
  );

  const standardsAccuracyFetch = useFetchClassroomStandardAccuracies({
    classroom_id: classroom.id,
    assignment_id: assignment.id,
  });

  // sort by standard id to prevent jumping on page reload
  const standardAccuracies = useMemo(
    () =>
      (standardsAccuracyFetch.data?.average_standard_accuracies || []).sort(
        (a, b) => {
          if (a.standard_id > b.standard_id) return 1;
          else return -1;
        }
      ),
    [standardsAccuracyFetch.data?.average_standard_accuracies]
  );

  const standardsFetch = useFetchStandards({
    ids: standardAccuracies.map((a) => a.standard_id),
    enabled: !!standardAccuracies.length,
    limit: standardAccuracies.length || 1,
    offset: 0,
  });

  const standardsData: IStandardProps[] = useMemo(() => {
    if (!standardsAccuracyFetch.data || !standardsFetch.data) return [];

    const standardMap: { [key: string]: IStandard } = {};
    const pearStandardMap: { [key: string]: Standard } = {};

    standardsFetch.data.standards.forEach((s) => {
      standardMap[s.id] = s;
    });
    (standardsFetch.data.pear_standards || []).forEach((pearStandard) => {
      pearStandardMap[pearStandard.id] = pearStandard;
    });

    return standardsAccuracyFetch.data.average_standard_accuracies
      .filter(({ standard_id }) => !!standardMap[standard_id])
      .map(({ standard_id, pear_standard_id, average_accuracy: accuracy }) => {
        const standard = standardMap[standard_id];
        const pearStandard = pearStandardMap[pear_standard_id];

        return {
          accuracy: Math.round(accuracy * 100),
          standard,
          pearStandard,
        };
      });
  }, [standardsAccuracyFetch.data, standardsFetch.data]);

  useErrorToast(standardsAccuracyFetch.error);
  useErrorToast(standardsFetch.error);

  const isLoading =
    standardsAccuracyFetch.isLoading || standardsFetch.isLoading;

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <AdminStandardsTab
          standardsData={standardsData}
          hasNoPremiumAccess={hasNoPremiumAccess}
        />
      )}
    </>
  );
};
