import { Box } from "@chakra-ui/react";
import { Standard } from "@goguardian/types-psi";
import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyCard } from "adminComponents/molecules/EmptyCard";
import { StandardsCard } from "adminComponents/molecules/StandardsCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { IStandard } from "links/lib/types";

export interface StandardsCardProps {
  accuracy?: number;
  standard: IStandard;
  pearStandard?: Standard;
}

interface IProps {
  standardsData?: Array<StandardsCardProps>;
  hasNoPremiumAccess?: boolean;
}

export const StandardsTab: React.FC<IProps> = ({
  standardsData,
  hasNoPremiumAccess,
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });

  return (
    <Box mt={pxToRem(40)}>
      {standardsData?.length ? (
        standardsData.map((standardsCardData) => {
          const { standard } = standardsCardData;

          return (
            <Box mb={pxToRem(12)} key={standard.id}>
              <StandardsCard
                {...standardsCardData}
                accuracyType="class"
                standard={standard}
                hasNoPremiumAccess={hasNoPremiumAccess}
              />
            </Box>
          );
        })
      ) : (
        <EmptyCard
          text={t("classDetailEmptyCards.standardsText")}
          title={t("classDetailEmptyCards.standardsTitle")}
        />
      )}
    </Box>
  );
};
