import { QuestionIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Collapse,
  Flex,
  Image,
  ModalFooter,
  Show,
  Square,
  Stack,
  StackDirection,
  Tooltip,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ColorScheme } from "adminComponents";
import { Button } from "adminComponents/atoms/Button";
import { Card } from "adminComponents/atoms/Card";
import { CertifiedText } from "adminComponents/atoms/CertifiedText";
import { CircledNumber } from "adminComponents/atoms/CircledNumber";
import { ClampedTooltip } from "adminComponents/atoms/ClampedTooltip";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Icon, IconType } from "adminComponents/atoms/Icon";
import { IconButtonWithTooltip } from "adminComponents/atoms/IconButtonWithTooltip";
import { Modal } from "adminComponents/atoms/Modal";
import { PremiumText } from "adminComponents/atoms/PremiumText";
import { PremiumTooltip } from "adminComponents/atoms/PremiumTooltip";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Tag, TagColor } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { ToggleButton } from "adminComponents/atoms/ToggleButton";
import { IHandleViewStudentResponses } from "adminComponents/molecules/ClassroomAnswerResultsMetric";
import {
  IconButtonDropdown,
  MenuItemDetails as IconMenuItemDetails,
} from "adminComponents/molecules/IconButtonDropdown";
import { PieChartWithLabel } from "adminComponents/molecules/PieChartWithLabel";
import { PracticeSetQuestionCardAnswer } from "adminComponents/molecules/PracticeSetQuestionCardAnswer";
import { QuestionDiagram } from "adminComponents/molecules/QuestionDiagram";
import { isRichTextEmpty } from "adminComponents/organisms/RichTextEditor/util";
import { isPremiumItem } from "adminComponents/utils/isPremiumItem";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { usePearStandardsEnabled } from "adminComponents/utils/usePearStandardsEnabled";
import { useAuth } from "links/lib/features/auth";
import { useFetchSkill } from "links/lib/features/skills";
import {
  IPracticeSetItem,
  IPracticeSetSessionItemReport,
  IUser,
  QuestionType,
  UserRole,
} from "links/lib/types";
import { RichTextRenderer } from "sharedComponents/atoms/RichTextRenderer";

export enum PracticeSetQuestionCardType {
  Answers = "ANSWERS",
  ClassroomResults = "CLASSROOM_RESULTS",
  StudentPracticeSetResults = "STUDENT_PRACTICE_SET_RESULTS",
  StudentAssignmentResults = "STUDENT_ASSIGNMENT_RESULTS",
  Generated = "GENERATED",
}

export interface IProps {
  authUser?: IUser;
  isPublic?: boolean;
  isInPremiumSet?: boolean; // If true, will blur prompt/answer on third and higher items
  practiceSetItem: IPracticeSetItem;
  questionNumber: number;
  handleEdit?: (item: IPracticeSetItem) => void;
  handleDuplicate?: (item: IPracticeSetItem) => void;
  handleDelete?: (item: IPracticeSetItem) => void;
  handlePreview?: (item: IPracticeSetItem) => void;
  handleAddToSet?: (item: IPracticeSetItem) => void;
  handleRemoveFromSet?: (item: IPracticeSetItem) => void;
  handleViewStudentResponses?: IHandleViewStudentResponses;
  topRightContent?: React.ReactNode;
  cardBorderColor?: ColorScheme;
  cardType: PracticeSetQuestionCardType;
  color?: ColorScheme;
  draggable?: boolean;
  isSelected?: boolean;
  cursorStyle?: "grab" | "grabbing";
  classroomResultsReport?:
    | IPracticeSetSessionItemReport
    | IPracticeSetSessionItemReport[];
  studentResultsReport?: IPracticeSetSessionItemReport;
  studentsCount?: number;
  allowEdit?: boolean;
  allowDuplicate?: boolean;
  allowRemove?: boolean;
  showPremiumMarker?: boolean;
  hideAnswers?: boolean;
  isLoading?: boolean;
}

export const PracticeSetQuestionCard: React.FC<IProps> = ({
  authUser,
  isPublic,
  isInPremiumSet,
  handleEdit,
  handleDuplicate,
  handleDelete,
  handleViewStudentResponses,
  handleAddToSet,
  handleRemoveFromSet,
  questionNumber,
  practiceSetItem,
  cardBorderColor,
  cardType,
  color = "primary.warm-black",
  draggable,
  isSelected,
  cursorStyle,
  classroomResultsReport,
  studentResultsReport,
  topRightContent,
  studentsCount,
  allowRemove = true,
  allowDuplicate = true,
  allowEdit = true,
  showPremiumMarker = true,
  hideAnswers = false,
  isLoading = false,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });
  const { hasNoPremiumAccess } = useAuth();
  const pearStandardsEnabled = usePearStandardsEnabled();

  const needsPremium = isPremiumItem(practiceSetItem);
  const isStarterSetItem =
    practiceSetItem.is_certified && !practiceSetItem.is_premium;
  const hidePremiumFeature =
    hasNoPremiumAccess && needsPremium && !isStarterSetItem;

  const isContentSpecialist = authUser?.role === UserRole.ContentSpecialist;

  const fetchSkill = useFetchSkill({
    id: practiceSetItem.skill_id,
    disabled: !isContentSpecialist,
  });

  const handleEditItem = () => handleEdit?.(practiceSetItem);
  const handleDuplicateItem = () => handleDuplicate?.(practiceSetItem);
  const handleDeleteItem = () => handleDelete?.(practiceSetItem);
  // TODO: Add when preview function built
  // const handlePreviewItem = () => handlePreview?.(practiceSetItem);

  const menuItems: IconMenuItemDetails[] = [
    // TODO: Add when preview function built
    // { label: t("common.preview"), handleClick: handlePreviewItem },
  ];

  if (allowEdit) {
    menuItems.push({ label: t("common.edit"), handleClick: handleEditItem });
  }

  if (allowDuplicate) {
    menuItems.push({
      label: t("common.duplicate"),
      handleClick: handleDuplicateItem,
    });
  }

  if (allowRemove) {
    menuItems.push({
      label: t("common.delete"),
      handleClick: handleDeleteItem,
    });
  }

  const { isOpen, onToggle } = useDisclosure();

  const [imageModalImageUrl, setImageModalImageUrl] = useState("");
  const [imageModalImageAltText, setImageModalImageAltText] = useState("");

  const {
    isOpen: isOpenImageModal,
    onClose: onCloseImageModal,
    onOpen: onOpenImageModal,
  } = useDisclosure();

  const handleOpenImageModal = (imageUrl: string, imageAltText: string) => {
    setImageModalImageUrl(imageUrl);
    setImageModalImageAltText(imageAltText);
    onOpenImageModal();
  };

  const {
    image_alt_text,
    image_url,
    multiple_choice,
    question_type,
    text_response,
    diagram_labels,
    diagram,
    term_definition,
    standards,
    pear_standards,
    feedback,
    number_response,
    draw,
    classify,
    multiple_select,
  } = practiceSetItem;

  const {
    Diagram,
    MultipleChoice,
    TermDefinition,
    TextResponse,
    Draw,
    NumberResponse,
    Classify,
    MultipleSelect,
  } = QuestionType;

  const {
    ClassroomResults,
    StudentPracticeSetResults,
    StudentAssignmentResults,
    Answers,
    Generated,
  } = PracticeSetQuestionCardType;

  let tagColor: TagColor | undefined;
  let tagText;
  let question;
  let isPremium = false;
  const isDiagramCard = question_type === Diagram;
  const isDrawCard = question_type === Draw;
  const isClassroomResultsCard = cardType === ClassroomResults;
  const isStudentResultsCard =
    cardType === StudentPracticeSetResults ||
    cardType === StudentAssignmentResults;
  const isStudentPracticeSetResults = cardType === StudentPracticeSetResults;
  const isAnswersCard = cardType === Answers;
  const isGeneratedCard = cardType === Generated;
  const showQuestionImage = image_url && !isDiagramCard && !draggable;
  const hasPremiumMedia =
    practiceSetItem.audio_url !== "" || practiceSetItem.video_url !== "";

  if (question_type === MultipleChoice) {
    question = multiple_choice?.prompt;
    tagColor = "purple";
    tagText = t("practiceSetQuestionCard.labelMultipleChoice");
  } else if (question_type === MultipleSelect) {
    question = multiple_select?.prompt;
    tagColor = "purple";
    tagText = t("practiceSetQuestionCard.labelMultipleSelect");
  } else if (question_type === TermDefinition) {
    question = term_definition?.definition;
    tagColor = "blue";
    tagText = t("practiceSetQuestionCard.labelTerm");
  } else if (question_type === TextResponse) {
    question = text_response?.prompt;
    tagColor = "green";
    tagText = t("practiceSetQuestionCard.labelTextResponse");
  } else if (question_type === Diagram) {
    question = diagram?.prompt;
    tagColor = "red";
    tagText = t("practiceSetQuestionCard.labelDiagram");
    isPremium = true;
  } else if (question_type === NumberResponse) {
    question = number_response?.prompt;
    tagColor = "green-grey"; // TODO
    tagText = t("practiceSetQuestionCard.labelNumberResponse");
  } else if (question_type === Classify) {
    question = classify?.prompt;
    tagColor = "orange";
    tagText = t("practiceSetQuestionCard.labelClassify");
    isPremium = true;
  } else if (question_type === Draw) {
    question = draw?.prompt;
    tagColor = "light-blue";
    tagText = t("practiceSetQuestionCard.labelDraw");
  }

  const renderDiagram = () =>
    diagram_labels &&
    image_url && (
      <Box
        marginTop={{ base: pxToRem(33), md: pxToRem(61) }}
        filter={blurContent ? `blur(5px)` : undefined}
      >
        <QuestionDiagram
          diagramLabels={diagram_labels}
          imageSrc={image_url}
          imageAltText={image_alt_text}
          handleOpenImageModal={handleOpenImageModal}
        />
      </Box>
    );

  const smallContentDirectionReverse = useBreakpointValue({
    base: "column-reverse",
    sm: "column-reverse",
    md: "row",
  });

  const displayStandards = pearStandardsEnabled
    ? pear_standards || []
    : standards;

  const renderTopRightContent = () => {
    if (topRightContent) {
      return topRightContent;
    } else if (draggable) {
      return (
        <IconButtonWithTooltip
          shape="type2"
          variant="outline"
          icon="rearrange"
          cursor="grab"
          aria-hidden
          tabIndex={-1}
          ariaLabel={t("common.drag")}
          tooltipProps={{ popoverVariant: "default" }}
        />
      );
    } else if (isClassroomResultsCard) {
      let percentage = undefined;
      let showContent = true;
      if (
        Array.isArray(classroomResultsReport) &&
        classroomResultsReport.length
      ) {
        percentage =
          (classroomResultsReport.reduce((prev, curr) => {
            return prev + curr.accuracy;
          }, 0) /
            classroomResultsReport.length) *
          100;
        showContent =
          classroomResultsReport.length > 0 &&
          classroomResultsReport[0].question_type !== QuestionType.Draw;
      } else if (
        !Array.isArray(classroomResultsReport) &&
        typeof classroomResultsReport?.accuracy === "number"
      ) {
        percentage = classroomResultsReport.accuracy * 100;
        showContent =
          classroomResultsReport.question_type !== QuestionType.Draw;
      }

      return showContent ? (
        <PieChartWithLabel
          percentage={percentage}
          text={t("practiceSetQuestionCard.labelClassAccuracy")}
        />
      ) : (
        <></>
      );
    } else if (isStudentResultsCard) {
      let icon: IconType | null;
      let iconColor: ColorScheme | null;
      let backgroundColor: ColorScheme | null;
      let correctness;
      let iconRotation = 0;

      if (studentResultsReport?.responses[0]?.is_correct === undefined) {
        icon = null;
        iconColor = null;
        backgroundColor = null;
        correctness = null;
      } else if (studentResultsReport?.accuracy === 1) {
        icon = "check";
        iconColor = "primary.warm-black";
        backgroundColor = "utility.question-green";
        correctness = t("practiceSetQuestionCard.labelCorrect");
      } else if (studentResultsReport?.accuracy === 0) {
        icon = "close";
        iconColor = "primary.white";
        backgroundColor = "utility.question-red";
        correctness = t("practiceSetQuestionCard.labelIncorrect");
      } else {
        icon = "remove";
        iconColor = "primary.warm-black";
        backgroundColor = "primary.light-gray";
        correctness = t("practiceSetQuestionCard.labelPartialCredit");
        iconRotation = -45;
      }

      const attempts = studentResultsReport?.attempts
        ? studentResultsReport?.attempts
        : studentResultsReport?.total_responses;

      const attemptsLabel =
        attempts === 1
          ? t("practiceSetQuestionCard.labelAttemptsSingular")
          : t("practiceSetQuestionCard.labelAttemptsPlural", {
              number: attempts,
            });

      return (
        <Flex gap={pxToRem(40)}>
          {isStudentPracticeSetResults &&
            studentResultsReport?.question_type !== QuestionType.Draw && (
              <Text variant="adminP1">{attemptsLabel}</Text>
            )}
          {icon && iconColor && backgroundColor && (
            <Show above={isStudentPracticeSetResults ? "md" : undefined}>
              <Flex gap={pxToRem(12)} align="center">
                <Icon
                  flexShrink={0}
                  variant="circleWithBackgroundColor"
                  icon={icon}
                  iconColor={iconColor}
                  backgroundColor={backgroundColor}
                  transform={`rotate(${iconRotation}deg)`}
                />
                <Text variant="adminP2">{correctness}</Text>
              </Flex>
            </Show>
          )}
        </Flex>
      );
    } else if (!isPublic) {
      const top = hidePremiumFeature
        ? "admin.cards.smallWithBanner"
        : "admin.cards.small";
      return (
        <Flex
          position="absolute"
          top={top}
          right="admin.cards.small"
          gap={pxToRem(16)}
        >
          <Stack direction={smallContentDirectionReverse as StackDirection}>
            <Center>
              {practiceSetItem.is_certified && <CertifiedText type="item" />}
            </Center>
            <Center>
              {practiceSetItem.is_premium && <PremiumText type="item" />}
            </Center>
            <Show above="md">
              {allowEdit && (
                <PremiumTooltipRich isDisabled={!hidePremiumFeature}>
                  <IconButtonWithTooltip
                    disabled={hidePremiumFeature}
                    shape="type1"
                    icon="edit_outlined"
                    onClick={handleEditItem}
                    ariaLabel={t("common.edit")}
                    tooltipProps={{ popoverVariant: "default" }}
                    tooltipText={t("common.edit")}
                  />
                </PremiumTooltipRich>
              )}
              {allowDuplicate && (
                <PremiumTooltipRich isDisabled={!hidePremiumFeature}>
                  <IconButtonWithTooltip
                    disabled={hidePremiumFeature}
                    shape="type1"
                    icon="content_copy_outlined"
                    onClick={handleDuplicateItem}
                    ariaLabel={t("common.duplicate")}
                    tooltipProps={{ popoverVariant: "default" }}
                    tooltipText={t("common.duplicate")}
                  />
                </PremiumTooltipRich>
              )}
              {allowRemove && (
                <IconButtonWithTooltip
                  shape="type1"
                  icon="delete_outlined"
                  onClick={handleDeleteItem}
                  ariaLabel={t("common.delete")}
                  tooltipProps={{ popoverVariant: "default" }}
                  tooltipText={t("common.delete")}
                />
              )}
            </Show>
          </Stack>
          {!!menuItems.length && (
            <Show below="md">
              <IconButtonDropdown menuItems={menuItems} />
            </Show>
          )}
        </Flex>
      );
    } else if (isPublic) {
      const addToSetNotAllowed = hidePremiumFeature;

      return (
        <Stack direction={smallContentDirectionReverse as StackDirection}>
          <Center>
            {practiceSetItem.is_certified && <CertifiedText type="item" />}
          </Center>
          <Center>
            {showPremiumMarker && practiceSetItem.is_premium && (
              <PremiumText type="item" />
            )}
          </Center>
          {handleAddToSet && (
            <PremiumTooltipRich
              isDisabled={!addToSetNotAllowed}
              overrideText={t("premiumText.addPremiumItemTooltip")}
            >
              <Box>
                <Button
                  onClick={
                    addToSetNotAllowed || isLoading
                      ? undefined
                      : () => handleAddToSet(practiceSetItem)
                  }
                  isLoading={isLoading}
                  leftIcon={
                    addToSetNotAllowed ? (
                      <Icon icon="lock" />
                    ) : (
                      <Icon icon="add" />
                    )
                  }
                  variant="adminButtonOutlined"
                  isDisabled={addToSetNotAllowed || isLoading}
                >
                  {t("practiceSetQuestionCard.copyToSetButton")}
                </Button>
              </Box>
            </PremiumTooltipRich>
          )}
          {handleRemoveFromSet && (
            <Button
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={
                isLoading
                  ? undefined
                  : () => handleRemoveFromSet(practiceSetItem)
              }
              leftIcon={<Icon icon="delete" />}
              variant="adminButtonFilled"
            >
              {t("practiceSetQuestionCard.removeFromSetButton")}
            </Button>
          )}
        </Stack>
      );
    }
  };

  const questionTypeTag = (
    <Tag colorScheme={tagColor}>
      <Flex
        direction="row"
        alignItems="center"
        gap={{ base: pxToRem(4), md: pxToRem(6) }}
      >
        {showPremiumMarker && isPremium && (
          <PremiumTooltip>
            <Icon
              icon={hasNoPremiumAccess ? "lock" : "crown"}
              color="black"
              as="span"
              width={pxToRem(14)}
              height={pxToRem(14)}
              display="inline-flex"
              aria-label={t("common.premiumFeature")}
              aria-role="img"
              role="img"
            />
          </PremiumTooltip>
        )}
        <Box as="span">{tagText}</Box>
      </Flex>
    </Tag>
  );

  const blurContent =
    hasNoPremiumAccess && isInPremiumSet && practiceSetItem.order > 2;

  return (
    <>
      <Card
        borderColor={cardBorderColor ?? "primary.tan"}
        backgroundColor={isSelected ? "primary.warm-white" : "primary.white"}
        variant="adminCardThickBorder"
        cursor={cursorStyle}
      >
        {hidePremiumFeature && !isPublic && !authUser?.is_guest && (
          <Box
            marginBottom={pxToRem(15)}
            w="100%"
            h={pxToRem(45)}
            bg="primary.tan"
            borderRadius="xl"
          >
            <Flex
              justifyContent="center"
              alignItems="cented"
              h="100%"
              gap={pxToRem(10)}
            >
              <Icon icon="lock" color="black" />
              <Square>
                <Text>{t("practiceSetQuestionCard.invisible")}</Text>
              </Square>
              <Square>
                <PremiumTooltipRich>
                  <Square>
                    <QuestionIcon
                      color="utility.link"
                      width={pxToRem(16)}
                      height={pxToRem(16)}
                    />
                  </Square>
                </PremiumTooltipRich>
              </Square>
            </Flex>
          </Box>
        )}
        <Flex
          direction="row"
          align="center"
          gap={pxToRem(16)}
          marginBottom={pxToRem(28)}
        >
          <Box marginRight="auto">
            {isGeneratedCard ? (
              questionTypeTag
            ) : (
              <CircledNumber
                circleColor={cardBorderColor}
                color={color}
                number={questionNumber}
                small={draggable}
              />
            )}
          </Box>
          {renderTopRightContent()}
        </Flex>
        <Flex align="center" gap={{ base: pxToRem(20), md: pxToRem(24) }}>
          <Flex
            align="center"
            direction="row"
            gap={[pxToRem(12), null, pxToRem(24)]}
            w="full"
          >
            {showQuestionImage && (
              <ClampedTooltip
                hasArrow
                isDisabled={!image_alt_text}
                label={image_alt_text}
              >
                <Box
                  borderRadius={pxToRem(8)}
                  h={[pxToRem(72), pxToRem(120)]}
                  overflow="hidden"
                  w={[pxToRem(72), pxToRem(120)]}
                >
                  <Image
                    cursor={blurContent ? undefined : "pointer"}
                    alt={image_alt_text}
                    objectFit="cover"
                    src={image_url}
                    onClick={() => {
                      if (blurContent) return;
                      handleOpenImageModal(image_url, image_alt_text);
                    }}
                    filter={blurContent ? `blur(5px)` : undefined}
                  />
                </Box>
              </ClampedTooltip>
            )}
            <Box as={Heading} variant="adminH6" display="block" minWidth={0}>
              {question && (
                <RichTextRenderer
                  contentStyle={{
                    color,
                    filter: blurContent ? `blur(5px)` : undefined,
                    WebkitFilter: blurContent ? `blur(5px)` : undefined,
                  }}
                  content={question}
                />
              )}
            </Box>
          </Flex>
          {isClassroomResultsCard && !draggable && (
            <Show above="md">
              <Box marginLeft="auto">
                {/* Special case for showing draw item results, since there is too
                much data to display for the grid. Open a modal directly with all
                students' drawings */}
                {isDrawCard ? (
                  <Button
                    variant="adminTextButtonMedium"
                    onClick={() =>
                      handleViewStudentResponses?.(
                        practiceSetItem,
                        (
                          classroomResultsReport as IPracticeSetSessionItemReport
                        ).responses
                      )
                    }
                  >
                    {t("practiceSetQuestionCard.labelViewResults")}
                  </Button>
                ) : (
                  <ToggleButton
                    handleToggle={onToggle}
                    isOpen={isOpen}
                    openLabel={t("practiceSetQuestionCard.labelHideResults")}
                    closedLabel={t("practiceSetQuestionCard.labelViewResults")}
                  />
                )}
              </Box>
            </Show>
          )}
        </Flex>
        {isDiagramCard && renderDiagram()}
        {!draggable && (
          <>
            {isClassroomResultsCard && (
              <Show below="md">
                <Box
                  marginTop={pxToRem(12)}
                  marginBottom={isOpen ? pxToRem(28) : 0}
                >
                  {isDrawCard ? (
                    <Button
                      variant="adminTextButtonSmall"
                      onClick={() =>
                        handleViewStudentResponses?.(
                          practiceSetItem,
                          (
                            classroomResultsReport as IPracticeSetSessionItemReport
                          ).responses
                        )
                      }
                    >
                      {t("practiceSetQuestionCard.labelViewResults")}
                    </Button>
                  ) : (
                    <ToggleButton
                      handleToggle={onToggle}
                      isOpen={isOpen}
                      openLabel={t("practiceSetQuestionCard.labelHideResults")}
                      closedLabel={t(
                        "practiceSetQuestionCard.labelViewResults"
                      )}
                    />
                  )}
                </Box>
              </Show>
            )}

            {!hideAnswers && (
              <Collapse in={!isClassroomResultsCard || isOpen} animateOpacity>
                <Box
                  marginTop={{
                    base: isDiagramCard ? pxToRem(34) : pxToRem(12),
                    md: isDiagramCard ? pxToRem(57) : pxToRem(30),
                  }}
                >
                  <PracticeSetQuestionCardAnswer
                    color={color}
                    practiceSetItem={practiceSetItem}
                    answerDisplayType={cardType}
                    classroomResultsReport={classroomResultsReport}
                    studentResultsReport={studentResultsReport}
                    handleViewStudentResponses={handleViewStudentResponses}
                    handleOpenImageModal={handleOpenImageModal}
                    studentsCount={studentsCount}
                    hasNoPremiumAccess={hasNoPremiumAccess}
                    isInPremiumSet={isInPremiumSet}
                  />
                </Box>
              </Collapse>
            )}

            {!isGeneratedCard && (
              <>
                <Divider
                  color="primary.light-gray"
                  marginTop={{ base: pxToRem(21), md: pxToRem(30) }}
                />
                <Flex
                  direction="row"
                  wrap="wrap"
                  gap={{ base: pxToRem(8), md: pxToRem(12) }}
                  marginTop={{ base: pxToRem(21), md: pxToRem(30) }}
                >
                  {questionTypeTag}
                  {hasPremiumMedia &&
                    (showPremiumMarker || hasNoPremiumAccess) && (
                      <Tag colorScheme="utility.premium">
                        <Flex
                          direction="row"
                          alignItems="center"
                          gap={{ base: pxToRem(4), md: pxToRem(6) }}
                        >
                          {(hasNoPremiumAccess || showPremiumMarker) && (
                            <PremiumTooltip>
                              <Box
                                aria-label={t("common.premiumFeature")}
                                aria-role="img"
                                role="img"
                                as="span"
                                backgroundColor="utility.premium"
                                boxSize={pxToRem(16)}
                                alignItems="center"
                                justifyContent="center"
                                display="inline-flex"
                                borderRadius="full"
                              >
                                <Icon
                                  as="span"
                                  width={pxToRem(14)}
                                  height={pxToRem(14)}
                                  display="inline-flex"
                                  icon={hasNoPremiumAccess ? "lock" : "crown"}
                                  color="black"
                                />
                              </Box>
                            </PremiumTooltip>
                          )}
                          <Box as="span">{t("common.media")}</Box>
                        </Flex>
                      </Tag>
                    )}

                  {isContentSpecialist && (
                    <>
                      {fetchSkill.data?.skill && (
                        <Tag>
                          <Text fontWeight="bold" as="span">
                            {fetchSkill.data.skill.code}:
                          </Text>{" "}
                          {fetchSkill.data.skill.description}
                        </Tag>
                      )}
                      {practiceSetItem.cnc_code && (
                        <Tag>{practiceSetItem.cnc_code}</Tag>
                      )}
                    </>
                  )}

                  {/* Push remaining tags to the right */}
                  <Box marginRight={{ md: "auto" }}></Box>

                  {displayStandards.map((s) => (
                    <Tooltip
                      key={s.id}
                      label={`${s.root_label || s.label}: ${s.description}`}
                    >
                      <Box maxW="20%">
                        <Tag>{s.root_label || s.label}</Tag>
                      </Box>
                    </Tooltip>
                  ))}

                  {isAnswersCard && !isRichTextEmpty(feedback) && (
                    <Tag leftIcon="check">
                      {t("practiceSetQuestionCard.labelFeedback")}
                    </Tag>
                  )}

                  {displayStandards.length > 0 && isAnswersCard && (
                    <Tag leftIcon="check">
                      {t("practiceSetQuestionCard.labelStandards", {
                        number: displayStandards.length,
                      })}
                    </Tag>
                  )}
                </Flex>
              </>
            )}
          </>
        )}
      </Card>

      {isOpenImageModal && (
        <Modal
          isOpen={isOpenImageModal}
          onClose={onCloseImageModal}
          size="6xl"
          showBackButton={false}
        >
          <VStack w="full" justifyItems="center" paddingTop="3rem">
            <Image
              src={imageModalImageUrl}
              maxH={`calc(100vh - ${pxToRem(240)})`}
              alt={imageModalImageAltText}
            />
          </VStack>

          <ModalFooter pt={pxToRem(16)}>
            <Button variant="adminButtonFilled" onClick={onCloseImageModal}>
              {t("common.close")}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
