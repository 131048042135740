import { useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutateFunction } from "react-query";
import { usePrevious } from "react-use";

import { IOption } from "adminComponents/atoms/Dropdown";
import { TabData, Tabs } from "adminComponents/atoms/Tabs";
import { EditPracticeSetMediaModalContextProvider } from "adminComponents/contexts/EditPracticeSetMediaModalContext";
import { CreateClassificationTabForm } from "adminComponents/molecules/CreateQuestionClassificationTab";
import { CreateDiagramTabForm } from "adminComponents/molecules/CreateQuestionDiagramTab";
import { CreateDrawTabForm } from "adminComponents/molecules/CreateQuestionDrawTab";
import {
  CreateFeedbackTabForm,
  CreateQuestionFeedbackTab,
} from "adminComponents/molecules/CreateQuestionFeedbackTab";
import {
  CreateInternalTabForm,
  CreateQuestionInternalTab,
} from "adminComponents/molecules/CreateQuestionInternalTab";
import { CreateMultipleChoiceTabForm } from "adminComponents/molecules/CreateQuestionMultipleChoiceTab";
import { CreateNumberResponseTabForm } from "adminComponents/molecules/CreateQuestionNumberResponseTab";
import {
  CreateQuestionStandardsTab,
  CreateStandardTabForm,
} from "adminComponents/molecules/CreateQuestionStandardsTab";
import { CreateTermTabForm } from "adminComponents/molecules/CreateQuestionTermTab";
import { CreateTextResponseTabForm } from "adminComponents/molecules/CreateQuestionTextResponseTab";
import { AdminFlyout } from "adminComponents/molecules/Flyout";
import {
  CreateQuestionFormDataType,
  getCreateQuestionFormData,
} from "adminComponents/utils";
import { useAnalytics } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { usePear } from "links/lib/features/pear";
import {
  AnalyticsEvent,
  IPracticeSetItem,
  ISkill,
  IStandard,
  QuestionType,
  RichValue,
} from "links/lib/types";
import {
  IGenerateFeedbackArgs,
  IGeneratePracticeSetItemFeedbackResponse,
} from "screens/TeacherPracticeSetDetail/PracticeSetItemFlyout/hooks/useGenerateFeedback";

import { isRichTextEmpty } from "../RichTextEditor/util";
import { GenerateFeedbackPreviewModal } from "./components/GenerateFeedbackPreviewModal";
import { QuestionTab } from "./components/QuestionTab";

const CREATE_QUESTION_TABS = [
  "Term",
  "Multiple Choice",
  "Text Response",
  "Number Response",
  "Diagram",
  "Draw",
  "Classification",
  "Feedback",
  "Standards",
  "Internal",
] as const;
export type CreateQuestionTabType = typeof CREATE_QUESTION_TABS[number];

type CreateQuestionFormDataKey = keyof CreateQuestionFormDataType;

interface IProps {
  isOpen: boolean;
  isSubmitting: boolean;
  isLoadingStandards: boolean;
  isInternalUser: boolean;
  isImageMode?: boolean;
  flyoutType?: QuestionType;
  questionTypes: IOption[];
  grades: IOption[];
  gradeLevelIdFilter: string;
  subjects: IOption[];
  subjectIdFilter: string;
  states: IOption[];
  regionFilter: string;
  defaultValue?: IPracticeSetItem;
  skills?: ISkill[];
  standards?: IStandard[];
  handleChangeFilter: (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => void;
  handleSaveAndClose: (data: CreateQuestionFormDataType) => void;
  handleStandardsSearch: (search: string) => void;
  handleSkillsSearch: (search: string) => void;
  handleClose: () => void;
  showPremiumMarker?: boolean;
  handleGenerateFeedback?: UseMutateFunction<
    IGeneratePracticeSetItemFeedbackResponse,
    unknown,
    IGenerateFeedbackArgs,
    unknown
  >;
  isGeneratingFeedback?: boolean;
  generatedFeedback?: RichValue;
}

const isIPracticeSetItem = <T,>(
  data: IPracticeSetItem | T | undefined
): data is IPracticeSetItem => {
  if (!data) return false;
  return (data as IPracticeSetItem).id !== undefined;
};

const checkStandardsTabValidation = (
  data: CreateStandardTabForm | IPracticeSetItem | undefined
) => {
  if (isIPracticeSetItem(data)) {
    return data.standards.length > 0 || (data.pear_standards || []).length > 0;
  }

  return !!data && (data.standards.length > 0 || data.pearStandards.length > 0);
};

const checkInternalTabValidation = (
  data: CreateInternalTabForm | IPracticeSetItem | undefined
) => {
  if (isIPracticeSetItem(data)) {
    return (
      !!data.skill ||
      data.is_certified ||
      data.is_premium ||
      data.cnc_code !== ""
    );
  }
  return (
    !!data &&
    (!!data.skill || data.isCertified || data.isPremium || data.cncCode !== "")
  );
};

const checkFeedbackTabValidation = (
  data: CreateFeedbackTabForm | IPracticeSetItem | undefined
) => {
  if (isIPracticeSetItem(data)) {
    return !isRichTextEmpty(data.feedback);
  }
  return !!data && !isRichTextEmpty(data.feedback);
};

export const QuestionFlyout: React.FC<IProps> = ({
  isOpen,
  isSubmitting,
  isLoadingStandards,
  isInternalUser,
  isImageMode = false,
  grades,
  gradeLevelIdFilter,
  subjects,
  subjectIdFilter,
  states,
  regionFilter,
  flyoutType = "term",
  defaultValue,
  skills = [],
  standards = [],
  handleSaveAndClose,
  handleChangeFilter,
  handleStandardsSearch,
  handleSkillsSearch,
  handleClose: _handleClose,
  showPremiumMarker = true,
  isGeneratingFeedback = false,
  handleGenerateFeedback,
  generatedFeedback,
}) => {
  const pear = usePear();
  const { t } = useTranslation("admin", { useSuspense: false });
  const { hasNoPremiumAccess } = useAuth();
  const feedbackRef = useRef<HTMLDivElement>(null);
  const standardsRef = useRef<HTMLDivElement>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabValidation, setTabValidation] = useState<
    Record<CreateQuestionTabType, boolean>
  >({
    Feedback: checkFeedbackTabValidation(defaultValue),
    Standards: checkStandardsTabValidation(defaultValue),
    Internal: checkInternalTabValidation(defaultValue),
    Term: false,
    "Multiple Choice": false,
    "Text Response": false,
    "Number Response": false,
    Diagram: false,
    Draw: false,
    Classification: false,
  });

  const {
    isOpen: isGenerateFeedbackPreviewModalOpen,
    onOpen: onOpenGenerateFeedbackPreviewModal,
    onClose: onCloseGenerateFeedbackPreviewModal,
  } = useDisclosure();

  const { trackEvent } = useAnalytics();

  // State update on open
  const prevIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (isOpen && !prevIsOpen) {
      setFormData(
        getCreateQuestionFormData(flyoutType, defaultValue, isInternalUser)
      );
      setTabValidation({
        Feedback: checkFeedbackTabValidation(defaultValue),
        Standards: checkStandardsTabValidation(defaultValue),
        Internal: checkInternalTabValidation(defaultValue),
        // The question tab validity will be overwritten
        // by question tab content on mount
        Term: false,
        "Multiple Choice": false,
        "Text Response": false,
        "Number Response": false,
        Diagram: false,
        Draw: false,
        Classification: false,
      });
      setIsFormDirty(false);
    }
  }, [isOpen, prevIsOpen, flyoutType, defaultValue, isInternalUser]);

  const [formData, setFormData] = useState<CreateQuestionFormDataType>(
    getCreateQuestionFormData(flyoutType, defaultValue, isInternalUser)
  );
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  // set the active tab index to first whenever flyout closes
  useEffect(() => {
    if (!isOpen) {
      setActiveTabIndex(0);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (isFormDirty) {
      const shouldClose = window.confirm(
        t("createQuestion.warnUnsavedChanges")
      );
      if (!shouldClose) return;
    }

    _handleClose();
  }, [_handleClose, isFormDirty, t]);

  const handleDirty = useCallback(() => setIsFormDirty(true), []);

  const handleBack = useCallback(() => {
    if (activeTabIndex === 0) {
      handleClose();
    } else {
      setActiveTabIndex((tIndex) => tIndex - 1);
    }
  }, [activeTabIndex, handleClose]);

  const handleUpdateTermTabData = (
    data: CreateTermTabForm,
    isValid: boolean
  ) => {
    setFormData((prevForm) => {
      return { ...prevForm, term: data };
    });

    setTabValidation((prev) => ({
      ...prev,
      Term: isValid,
    }));
  };

  const handleUpdateMultipleChoiceTabData = (
    isValid: boolean,
    data?: CreateMultipleChoiceTabForm
  ) => {
    if (data) {
      setFormData((prevForm) => {
        return { ...prevForm, multipleChoice: data };
      });
    }

    setTabValidation((prev) => ({
      ...prev,
      "Multiple Choice": isValid,
    }));
  };

  const handleUpdateDiagramTabData = (
    isValid: boolean,
    data?: CreateDiagramTabForm
  ) => {
    if (data) {
      setFormData((prevForm) => {
        return { ...prevForm, diagram: data };
      });
    }

    setTabValidation((prev) => ({
      ...prev,
      Diagram: isValid,
    }));
  };

  const handleUpdateDrawTabData = (
    isValid: boolean,
    data?: CreateDrawTabForm
  ) => {
    if (data) {
      setFormData((prevForm) => {
        return { ...prevForm, draw: data };
      });
    }

    setTabValidation((prev) => ({
      ...prev,
      Draw: isValid,
    }));
  };

  const handleUpdateClassificationTabData = (
    isValid: boolean,
    data?: CreateClassificationTabForm
  ) => {
    if (data) {
      setFormData((prevForm) => {
        return { ...prevForm, classify: data };
      });
    }

    setTabValidation((prev) => ({
      ...prev,
      Classification: isValid,
    }));
  };

  const handleUpdateTextResponseTabData = (
    isValid: boolean,
    data?: CreateTextResponseTabForm
  ) => {
    if (data) {
      setFormData((prevForm) => {
        return { ...prevForm, textResponse: data };
      });
    }

    setTabValidation((prev) => ({
      ...prev,
      "Text Response": isValid,
    }));
  };

  const handleUpdateNumberResponseTabData = (
    isValid: boolean,
    data?: CreateNumberResponseTabForm
  ) => {
    if (data) {
      setFormData((prevForm) => {
        return { ...prevForm, numberResponse: data };
      });
    }

    setTabValidation((prev) => ({
      ...prev,
      "Number Response": isValid,
    }));
  };

  const handleUpdateStandardTabData = (data: CreateStandardTabForm) => {
    setFormData((prevForm) => {
      return { ...prevForm, standard: data };
    });

    setTabValidation((prev) => ({
      ...prev,
      Standards: checkStandardsTabValidation(data),
    }));
  };

  const handleUpdateInternalTabData = (data: CreateInternalTabForm) => {
    setFormData((prevForm) => {
      return { ...prevForm, internal: data };
    });

    setTabValidation((prev) => ({
      ...prev,
      Internal: checkInternalTabValidation(data),
    }));
  };

  const handleUpdateFeedbackTabData = (data: CreateFeedbackTabForm) => {
    setFormData((prevForm) => {
      return { ...prevForm, feedback: data };
    });

    setTabValidation((prev) => ({
      ...prev,
      Feedback: checkFeedbackTabValidation(data),
    }));
  };

  const isQuestionDataValid = (() => {
    switch (flyoutType) {
      case QuestionType.TermDefinition:
        return tabValidation.Term;
      case QuestionType.Diagram:
        return tabValidation.Diagram;
      case QuestionType.Draw:
        return tabValidation.Draw;
      case QuestionType.Classify:
        return tabValidation.Classification;
      case QuestionType.MultipleChoice:
      case QuestionType.MultipleSelect:
        return tabValidation["Multiple Choice"];
      case QuestionType.NumberResponse:
        return tabValidation["Number Response"];
      case QuestionType.TextResponse:
        return tabValidation["Text Response"];
      default:
        return false;
    }
  })();

  const _handleSaveAndClose = (data: CreateQuestionFormDataType) => {
    // Do not save if already saving/loading
    if (isSubmitting) return;

    // If question data is invalid, then prevent save
    if (!isQuestionDataValid) return;

    const dataToSubmit: CreateQuestionFormDataType = Object.assign(
      {},
      formData,
      data
    );
    Object.keys(dataToSubmit).forEach((key) => {
      if (!dataToSubmit[key as CreateQuestionFormDataKey]) {
        delete dataToSubmit[key as CreateQuestionFormDataKey];
      }
    });

    handleSaveAndClose(dataToSubmit);
  };

  const _handleGenerateFeedback = useCallback(() => {
    if (hasNoPremiumAccess) {
      onOpenGenerateFeedbackPreviewModal();
      return;
    }

    trackEvent(
      AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_FeedbackTab_GenerateFeedback,
      {
        question_type: flyoutType,
      }
    );
    handleGenerateFeedback?.({ formData, practiceSetItemId: defaultValue?.id });
  }, [
    hasNoPremiumAccess,
    trackEvent,
    flyoutType,
    handleGenerateFeedback,
    formData,
    defaultValue?.id,
    onOpenGenerateFeedbackPreviewModal,
  ]);

  const _handleGenerateFeedbackFromFormData = useCallback(
    (theFormData: CreateQuestionFormDataType) => {
      if (hasNoPremiumAccess) {
        onOpenGenerateFeedbackPreviewModal();
        return;
      }

      trackEvent(
        AnalyticsEvent.TeacherDashboard_PracticeSetDetail_QuestionFlyout_QuestionTab_GenerateFeedback,
        {
          question_type: flyoutType,
        }
      );
      handleGenerateFeedback?.({
        formData: theFormData,
        practiceSetItemId: defaultValue?.id,
      });
    },
    [
      hasNoPremiumAccess,
      trackEvent,
      flyoutType,
      handleGenerateFeedback,
      defaultValue?.id,
      onOpenGenerateFeedbackPreviewModal,
    ]
  );

  const hasFeedback = checkFeedbackTabValidation(formData.feedback);

  const firstTabElem: TabData =
    flyoutType === QuestionType.TermDefinition
      ? {
          label: t("createTermsFlyout.tabTerm"),
          checked: tabValidation.Term,
          content: <></>,
        }
      : flyoutType === QuestionType.MultipleChoice ||
        flyoutType === QuestionType.MultipleSelect
      ? {
          label: t("createMultipleChoiceFlyout.tabMultipleChoice"),
          checked: tabValidation["Multiple Choice"],
          content: <></>,
        }
      : flyoutType === QuestionType.TextResponse
      ? {
          label: t("createNewTextResponseFlyout.tabTextResponse"),
          checked: tabValidation["Text Response"],
          content: <></>,
        }
      : flyoutType === QuestionType.NumberResponse
      ? {
          label: t("createNewNumberResponseFlyout.tabNumberResponse"),
          checked: tabValidation["Number Response"],
          content: <></>,
        }
      : flyoutType === QuestionType.Diagram
      ? {
          label: t("createDiagramFlyout.tabDiagram"),
          checked: tabValidation["Diagram"],
          content: <></>,
        }
      : flyoutType === QuestionType.Draw
      ? {
          label: t("createDrawFlyout.tabDraw"),
          checked: tabValidation["Draw"],
          content: <></>,
        }
      : flyoutType === QuestionType.Classify
      ? {
          label: t("createClassificationFlyout.tabClassification"),
          checked: tabValidation["Classification"],
          content: <></>,
        }
      : {
          label: "",
          checked: false,
          content: <></>,
        };

  const idleTitle =
    flyoutType === QuestionType.TermDefinition
      ? t("createTermsFlyout.title")
      : flyoutType === QuestionType.MultipleChoice
      ? t("createMultipleChoiceFlyout.title")
      : flyoutType === QuestionType.TextResponse
      ? t("createNewTextResponseFlyout.title")
      : flyoutType === QuestionType.NumberResponse
      ? t("createNewNumberResponseFlyout.title")
      : flyoutType === QuestionType.Diagram
      ? t("createDiagramFlyout.title")
      : flyoutType === QuestionType.Draw
      ? t("createDrawFlyout.title")
      : flyoutType === QuestionType.Classify
      ? t("createClassificationFlyout.title")
      : "";

  const editingTitle =
    flyoutType === QuestionType.TermDefinition
      ? t("createTermsFlyout.editTitle")
      : flyoutType === QuestionType.MultipleChoice
      ? t("createMultipleChoiceFlyout.editTitle")
      : flyoutType === QuestionType.TextResponse
      ? t("createNewTextResponseFlyout.editTitle")
      : flyoutType === QuestionType.NumberResponse
      ? t("createNewNumberResponseFlyout.editTitle")
      : flyoutType === QuestionType.Diagram
      ? t("createDiagramFlyout.editTitle")
      : flyoutType === QuestionType.Draw
      ? t("createDrawFlyout.editTitle")
      : flyoutType === QuestionType.Classify
      ? t("createClassificationFlyout.editTitle")
      : "";

  const title = defaultValue ? editingTitle : idleTitle;

  const tabData = [
    firstTabElem,
    {
      label: t("createTermsFlyout.tabFeedback"),
      checked: tabValidation.Feedback,
      content: <></>,
    },
    {
      label: t("createTermsFlyout.tabStandards"),
      checked: tabValidation.Standards,
      content: <></>,
    },
  ];

  if (isInternalUser) {
    tabData.push({
      label: t("createTermsFlyout.tabInternal"),
      checked: tabValidation.Internal,
      content: <></>,
    });
  }

  return (
    <>
      <AdminFlyout
        isOpen={isOpen}
        onClose={handleClose}
        title={title}
        showBack={activeTabIndex > 0}
        onBack={handleBack}
        trapFocus={!pear.isStandardsModalOpen}
      >
        <Tabs
          tabIndex={activeTabIndex}
          variant="adminFlyoutTabs"
          handleChange={setActiveTabIndex}
          showCheck
          tabData={tabData}
        />
        {activeTabIndex === 0 && (
          <QuestionTab
            defaultValue={formData}
            flyoutType={flyoutType}
            isImageMode={isImageMode}
            isQuestionDataValid={isQuestionDataValid}
            isSubmitting={isSubmitting}
            hasFeedback={hasFeedback}
            feedbackRef={feedbackRef}
            setActiveTabIndex={setActiveTabIndex}
            setTabValidation={setTabValidation}
            handleSaveAndClose={_handleSaveAndClose}
            handleUpdateClassificationTabData={
              handleUpdateClassificationTabData
            }
            handleUpdateDiagramTabData={handleUpdateDiagramTabData}
            handleUpdateDrawTabData={handleUpdateDrawTabData}
            handleUpdateMultipleChoiceTabData={
              handleUpdateMultipleChoiceTabData
            }
            handleUpdateNumberResponseTabData={
              handleUpdateNumberResponseTabData
            }
            handleUpdateTermTabData={handleUpdateTermTabData}
            handleUpdateTextResponseTabData={handleUpdateTextResponseTabData}
            showPremiumMarker={showPremiumMarker}
            handleDirty={handleDirty}
            handleGenerateFeedback={_handleGenerateFeedbackFromFormData}
            showGenerateFeedback
          />
        )}
        {activeTabIndex === 1 && (
          <EditPracticeSetMediaModalContextProvider
            showPremiumMarker={showPremiumMarker}
            defaultValue={
              defaultValue
                ? {
                    altText: defaultValue.image_alt_text,
                    imageUrl: defaultValue.image_url,
                    audioUrl: defaultValue.audio_url,
                    videoUrl: defaultValue.video_url,
                  }
                : undefined
            }
          >
            <CreateQuestionFeedbackTab
              ref={feedbackRef}
              canSubmit={isQuestionDataValid}
              isSubmitting={isSubmitting}
              handleChangeForm={handleUpdateFeedbackTabData}
              defaultValue={formData.feedback}
              hasStandards={!!formData.standard?.standards?.length}
              handleNextTab={() => {
                setActiveTabIndex(2);
                standardsRef.current?.scrollIntoView();
              }}
              handleSaveAndClose={(data: CreateFeedbackTabForm) =>
                _handleSaveAndClose({ feedback: data })
              }
              handleDirty={handleDirty}
              handleGenerate={_handleGenerateFeedback}
              isGenerating={isGeneratingFeedback}
              showGenerate={
                flyoutType === QuestionType.MultipleChoice ||
                flyoutType === QuestionType.MultipleSelect ||
                flyoutType === QuestionType.TextResponse ||
                flyoutType === QuestionType.NumberResponse
              }
              generatedFeedback={generatedFeedback}
            />
          </EditPracticeSetMediaModalContextProvider>
        )}
        {activeTabIndex === 2 && (
          <CreateQuestionStandardsTab
            ref={standardsRef}
            canSubmit={isQuestionDataValid}
            isSubmitting={isSubmitting}
            isLoadingStandards={isLoadingStandards}
            handleChangeForm={handleUpdateStandardTabData}
            handleSaveAndClose={(data: CreateStandardTabForm) =>
              _handleSaveAndClose({ standard: data })
            }
            grades={grades}
            gradeLevelIdFilter={gradeLevelIdFilter}
            subjects={subjects}
            subjectIdFilter={subjectIdFilter}
            states={states}
            regionFilter={regionFilter}
            standardOptions={standards}
            defaultValue={formData.standard}
            handleChangeFilter={handleChangeFilter}
            handleSearch={handleStandardsSearch}
            handleDirty={handleDirty}
          />
        )}
        {activeTabIndex === 3 && (
          <CreateQuestionInternalTab
            canSubmit={isQuestionDataValid}
            isSubmitting={isSubmitting}
            handleChangeForm={handleUpdateInternalTabData}
            handleSaveAndClose={(data: CreateInternalTabForm) =>
              _handleSaveAndClose({ internal: data })
            }
            skillOptions={skills}
            defaultValue={formData.internal}
            handleSearch={handleSkillsSearch}
            handleDirty={handleDirty}
          />
        )}
      </AdminFlyout>
      <GenerateFeedbackPreviewModal
        isOpen={isGenerateFeedbackPreviewModalOpen}
        onClose={onCloseGenerateFeedbackPreviewModal}
      />
    </>
  );
};
