import { Standard } from "@goguardian/types-psi";
import { useEffect, useState } from "react";

import { IStandardProps } from "adminComponents/screens/ClassroomPracticeSetReportScreen/components/StandardsTab";
import { useErrorToast } from "adminComponents/utils/toast";
import { useFetchClassroomStandardAccuracies } from "links/lib/features/classrooms";
import { IFetchPracticeSetItemsResponse } from "links/lib/features/practiceSetItems/useFetchPracticeSetItems";
import { IStandard } from "links/lib/types";

interface IUseStandardsDataParams {
  practiceSetItemsData?: IFetchPracticeSetItemsResponse;
  classroomId: string;
  practiceSetId: string;
  userId?: string;
  assignmentId?: string;
  practiceSetSessionId?: string;
}

interface IUseStandardsDataResponse {
  standardsData: IStandardProps[];
  isLoading: boolean;
}

export const usePracticeSetItemStandardsData = ({
  classroomId,
  practiceSetId,
  practiceSetItemsData,
  userId,
  assignmentId,
  practiceSetSessionId,
}: IUseStandardsDataParams): IUseStandardsDataResponse => {
  const [standardsData, setStandardsData] = useState<{
    isSet: boolean;
    data: IStandardProps[];
  }>({ isSet: false, data: [] });

  const {
    data: classroomPracticesetStandardsAccuracyData,
    isLoading,
    error: classroomPracticesetStandardsAccuracyError,
  } = useFetchClassroomStandardAccuracies({
    classroom_id: classroomId,
    practice_set_id: practiceSetId,
    user_id: userId,
    assignment_id: assignmentId,
    practice_set_session_id: practiceSetSessionId,
  });

  useErrorToast(classroomPracticesetStandardsAccuracyError);

  useEffect(() => {
    if (
      practiceSetItemsData &&
      classroomPracticesetStandardsAccuracyData &&
      !standardsData.isSet
    ) {
      const standardsMap = new Map<string, IStandard>();
      const pearStandardsMap = new Map<string, Standard>();
      practiceSetItemsData.practice_set_items.forEach((practiceSetItem) => {
        practiceSetItem.standards.forEach((standard) => {
          if (!standardsMap.has(standard.id)) {
            standardsMap.set(standard.id, standard);
          }
        });
        (practiceSetItem.pear_standards || []).forEach((pearStandard) => {
          if (!pearStandardsMap.has(pearStandard.id)) {
            pearStandardsMap.set(pearStandard.id, pearStandard);
          }
        });
      });

      const standardsData: IStandardProps[] = [];

      classroomPracticesetStandardsAccuracyData.average_standard_accuracies.forEach(
        (standardAccuracy) => {
          const standard = standardsMap.get(standardAccuracy.standard_id);
          const pearStandard = pearStandardsMap.get(
            standardAccuracy.pear_standard_id
          );
          if (standard) {
            standardsData.push({
              accuracy: Math.round(standardAccuracy.average_accuracy * 100),
              standard,
              pearStandard,
            });
          }
        }
      );
      setStandardsData({ isSet: true, data: standardsData });
    }
  }, [
    practiceSetItemsData,
    standardsData,
    classroomPracticesetStandardsAccuracyData,
  ]);

  return {
    standardsData: standardsData.data,
    isLoading,
  };
};
