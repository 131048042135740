import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSetItem } from "../../types";
import { usePear } from "../pear";

export interface IMutatePracticeSetItemArgs {
  practice_set_id: string;
  practice_set_items: Array<IPracticeSetItem>;
}

interface IMutatePracticeSetItemsResponse {
  practice_set_items: Array<IPracticeSetItem>;
}

export interface IUseMutatePracticeSetItemsProps {
  onSuccess?: (data: IMutatePracticeSetItemsResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useMutatePracticeSetItems(
  props?: IUseMutatePracticeSetItemsProps
): UseMutationResult<
  IMutatePracticeSetItemsResponse,
  unknown,
  IMutatePracticeSetItemArgs
> {
  const pear = usePear();
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutatePracticeSetItems = async (args: IMutatePracticeSetItemArgs) => {
    const { practice_set_items, practice_set_id } = args;

    const response = await axios({
      method: "put",
      url: `/v1/practice-sets/${practice_set_id}/items/bulk`,
      data: {
        practice_set_items: practice_set_items,
      },
    }).then((r) => r.data as IMutatePracticeSetItemsResponse);

    queryClient.invalidateQueries(["practiceSets", { id: practice_set_id }]);
    queryClient.invalidateQueries(["practiceSetItems", { practice_set_id }]);

    await pear.populatePracticeSetItemsStandards(response.practice_set_items);

    response.practice_set_items.forEach((practice_set_item) => {
      queryClient.setQueryData(
        ["practiceSetItems", { id: practice_set_item.id }],
        {
          practice_set_item: practice_set_item,
        }
      );
    });

    return response;
  };

  const mutation = useMutation(mutatePracticeSetItems, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
