import { IUseAsyncReturn, useAsync } from "../../hooks/useAsync";
import { useAxios } from "../../hooks/useAxios";
import {
  IPracticeSet,
  IPracticeSetItem,
  ISearchSuggestion,
  ISubjectCount,
  PracticeDataDocumentType,
  PracticeSetAvailability,
} from "../../types";
import { usePear } from "../pear";

export interface ISearchPracticeDataOnceParams {
  document_type?: PracticeDataDocumentType;
  term?: string;
  standards?: Array<string>;
  subjects?: Array<string>;
  subject_ids?: Array<string>;
  grade_levels?: Array<string>;
  grade_level_ids?: Array<string>;
  region?: string;
  page?: number;
  per_page?: number;
  include_shared: boolean;
  availability: PracticeSetAvailability;
  anonymous?: boolean;
  include_suggestions?: boolean;
  include_detailed_suggestions?: boolean;
  exclude_subject_counts?: boolean;
}

export interface ISearchPracticeDataOnceResponse {
  practice_sets: Array<IPracticeSet>;
  practice_set_items: Array<IPracticeSetItem>;
  subject_counts: Array<ISubjectCount>;
  total_count: number;
  page: number;
  per_page: number;
  suggestions: Array<string>;
  detailed_suggestions: Array<ISearchSuggestion>;
}

export interface IUseSearchPracticeDataOnce {
  onSuccess?: (data: ISearchPracticeDataOnceResponse) => void;
  onError?: () => void;
}

export default function useSearchPracticeDataOnce(
  props?: IUseSearchPracticeDataOnce
): IUseAsyncReturn<
  ISearchPracticeDataOnceResponse,
  ISearchPracticeDataOnceParams
> {
  const pear = usePear();
  const axios = useAxios();

  const search = async (params: ISearchPracticeDataOnceParams) => {
    const response = await axios
      .get(`/v1/search/practice-data${params.anonymous ? "/anon" : ""}`, {
        params,
      })
      .then((r) => r.data as ISearchPracticeDataOnceResponse);

    await pear.populatePracticeSetsStandards(response.practice_sets);
    await pear.populatePracticeSetItemsStandards(response.practice_set_items);

    return response;
  };

  const { isLoading, error, data, execute, isSuccess } = useAsync<
    ISearchPracticeDataOnceResponse,
    ISearchPracticeDataOnceParams
  >(search, {
    onSuccess: props?.onSuccess,
    onError: props?.onError,
  });

  return {
    isLoading,
    error,
    data,
    execute,
    isSuccess,
  };
}
