import qs from "qs";
import { useMemo } from "react";

import { ISearchQuery } from "lib/hooks/useLibraryFilter";
import { ISubject, IUser } from "links/lib/types";

export interface IArgs {
  subjects: Array<ISubject>;
  authUser?: IUser;
  useTeacherSubjectsAndGrades?: boolean;
}

export const useInitialQuery = ({
  subjects,
  authUser,
  useTeacherSubjectsAndGrades = true,
}: IArgs): ISearchQuery & {
  basicSearchPage?: number;
  expertPage?: number;
  communityPage?: number;
  domainPage?: number;
  privatePage?: number;
} => {
  return useMemo<{ basicSearchPage: number } & ISearchQuery>(() => {
    const paramString = window.location.search.slice(1);
    const params = qs.parse(paramString, { arrayLimit: 100 }) as {
      term?: string;
      basicSearchPage?: string;
      expertPage?: string;
      communityPage?: string;
      domainPage?: string;
      privatePage?: string;
      standardIds?: string[];
      pearStandardIds: string[];
      gradeLevelIds?: string[];
      subjectIds?: string[];
      certifiedOnly?: string;
      hidePremium?: string;
    };

    const hasParams = Object.keys(params).length;

    // If no params, then default to teacher subjects
    const subjectIds =
      params.subjectIds ??
      (hasParams || !useTeacherSubjectsAndGrades
        ? []
        : !authUser?.teacher_subjects
        ? []
        : subjects
            .filter((s) =>
              authUser?.teacher_subjects?.some(
                (ts) => ts.subject_id === s.id || ts.subject_id === s.parent_id
              )
            )
            .map((s) => s.id));

    // If no params, default to teacher grades
    const gradeLevelIds =
      params.gradeLevelIds ??
      (hasParams || !useTeacherSubjectsAndGrades
        ? []
        : authUser?.teacher_grade_levels?.map((g) => g.grade_level_id) ?? []);

    return {
      basicSearchPage: params.basicSearchPage
        ? parseInt(params.basicSearchPage, 10)
        : 1,
      expertPage: params.expertPage ? parseInt(params.expertPage, 10) : 1,
      communityPage: params.communityPage
        ? parseInt(params.communityPage, 10)
        : 1,
      domainPage: params.domainPage ? parseInt(params.domainPage, 10) : 1,
      privatePage: params.privatePage ? parseInt(params.privatePage, 10) : 1,
      term: params.term,
      filters: {
        subjectIds,
        gradeLevelIds,
        standardIds: params.standardIds ?? [],
        pearStandardIds: params.pearStandardIds ?? [],
        certifiedOnly: params.certifiedOnly === "true",
        hidePremium: params.hidePremium === "true",
        numQuestions: [],
        languages: [],
      },
    };
  }, [authUser, subjects, useTeacherSubjectsAndGrades]);
};
