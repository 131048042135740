import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IMultipleChoiceChoice,
  IPracticeSetItem,
  QuestionType,
} from "../../types";
import { usePear } from "../pear";

export interface IMutateMultipleChoiceArgs {
  id?: string;
  multiple_choice_id?: string;
  multiple_select_id?: string;
  practice_set_id: string;
  prompt: string;
  choices: Array<IMultipleChoiceChoice>;
  image_mode: boolean;
  feedback: string;
  feedback_image_url?: string;
  feedback_image_alt_text?: string;
  order?: number;
  is_higher_order_thinking: boolean;
  standards: Array<{ id: string; pear_standard_id: string }>;
  audio_url?: string;
  video_url?: string;
  image_url?: string;
  image_alt_text?: string;
  questionType: QuestionType;
  cnc_code?: string;
  skill_id?: string;
  is_certified: boolean;
  is_premium: boolean;
}

interface IMutateMultipleChoiceResponse {
  practice_set_item: IPracticeSetItem;
}

export interface IUseMutateMultipleChoiceProps {
  onSuccess?: (data: IMutateMultipleChoiceResponse) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export default function useMutateMultipleChoice(
  props?: IUseMutateMultipleChoiceProps
): UseMutationResult<
  IMutateMultipleChoiceResponse,
  unknown,
  IMutateMultipleChoiceArgs
> {
  const pear = usePear();
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutateMultipleChoice = async (args: IMutateMultipleChoiceArgs) => {
    const {
      id,
      practice_set_id,
      prompt,
      choices,
      image_mode,
      feedback,
      feedback_image_url,
      feedback_image_alt_text,
      is_higher_order_thinking,
      multiple_choice_id,
      multiple_select_id,
      standards,
      audio_url,
      video_url,
      image_url,
      image_alt_text,
      questionType,
      cnc_code,
      skill_id,
      is_certified,
      is_premium,
    } = args;

    let { order } = args;

    const multipleChoice =
      questionType === QuestionType.MultipleChoice
        ? {
            id: multiple_choice_id,
            prompt,
            choices,
            image_mode,
          }
        : undefined;

    const multipleSelect =
      questionType === QuestionType.MultipleSelect
        ? {
            id: multiple_select_id,
            prompt,
            choices,
            image_mode,
          }
        : undefined;

    // if create, then determine order at end of items list
    if (!id) {
      order = await axios
        .get(`/v1/practice-sets/${practice_set_id}/items`)
        .then((r) => {
          let maxOrder = 0;

          (
            r.data as { practice_set_items: Array<IPracticeSetItem> }
          )?.practice_set_items?.forEach(
            (i) => (maxOrder = Math.max(i.order, maxOrder))
          );

          return maxOrder + 1;
        });
    } else {
      order = order || 1;
    }

    const response = await axios({
      method: id ? "put" : "post",
      url: `/v1/practice-sets/${practice_set_id}/items${id ? "/" + id : ""}`,
      data: {
        practice_set_item: {
          multiple_select: multipleSelect,
          multiple_choice: multipleChoice,
          audio_url,
          video_url,
          image_url,
          image_alt_text,
          question_type: questionType,
          feedback,
          feedback_image_url,
          feedback_image_alt_text,
          order,
          is_higher_order_thinking,
          standards,
          cnc_code,
          skill_id,
          is_certified,
          is_premium,
        },
      },
    }).then((r) => r.data as IMutateMultipleChoiceResponse);

    await pear.populatePracticeSetItemStandards(response.practice_set_item);

    queryClient.invalidateQueries(["practiceSets", { id: practice_set_id }]);
    queryClient.invalidateQueries(["practiceSetItems", { practice_set_id }]);
    queryClient.setQueryData(
      ["practiceSetItems", { id: response.practice_set_item.id }],
      {
        practice_set_item: response.practice_set_item,
      }
    );

    return response;
  };

  const mutation = useMutation(mutateMultipleChoice, {
    onError: props?.onError,
    onSuccess: props?.onSuccess,
    onSettled: props?.onSettled,
  });

  return {
    ...mutation,
  };
}
