import { Box } from "@chakra-ui/react";
import { Standard } from "@goguardian/types-psi";
import React, { useMemo } from "react";

import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import {
  StandardsTab as AdminStandardsTab,
  StandardsCardProps,
} from "adminComponents/screens/ClassroomDetailScreen/components/StandardsTab";
import { pxToRem } from "adminComponents/utils";
import { useErrorToast } from "adminComponents/utils/toast";
import { usePageTrack } from "lib/contexts/analytics";
import { useAuth } from "links/lib/features/auth";
import { useFetchClassroomStandardAccuracies } from "links/lib/features/classrooms";
import { useFetchStandards } from "links/lib/features/standards";
import { AnalyticsPage, IClassroom, IStandard } from "links/lib/types";

interface IStandardsTabProps {
  classroom: IClassroom;
}

export const StandardsTab: React.FC<IStandardsTabProps> = ({ classroom }) => {
  const { hasNoPremiumAccess } = useAuth();
  usePageTrack(AnalyticsPage.TeacherDashboard_ClassroomDetail_Standards);

  const accuracyFetch = useFetchClassroomStandardAccuracies({
    classroom_id: classroom.id,
  });

  // sort by standard id to prevent jumping on page reload
  const accuracies = useMemo(
    () =>
      (accuracyFetch.data?.average_standard_accuracies || []).sort((a, b) => {
        if (a.standard_id > b.standard_id) return 1;
        else return -1;
      }),
    [accuracyFetch.data?.average_standard_accuracies]
  );

  const standardsFetch = useFetchStandards({
    ids: accuracies.map((a) => a.standard_id),
    enabled: !!accuracies.length,
    limit: accuracies.length || 1,
    offset: 0,
  });

  useErrorToast(accuracyFetch.error);
  useErrorToast(standardsFetch.error);

  const standardsData: Array<StandardsCardProps> = useMemo(() => {
    if (!accuracyFetch.data || !standardsFetch.data) return [];

    const standardMap: { [key: string]: IStandard } = {};
    const pearStandardMap: { [key: string]: Standard } = {};

    standardsFetch.data.standards.forEach((s) => {
      standardMap[s.id] = s;
    });
    (standardsFetch.data.pear_standards || []).forEach((pearStandard) => {
      pearStandardMap[pearStandard.id] = pearStandard;
    });

    return accuracyFetch.data.average_standard_accuracies
      .filter(({ standard_id }) => !!standardMap[standard_id])
      .map(({ standard_id, pear_standard_id, average_accuracy: accuracy }) => {
        const standard = standardMap[standard_id];
        const pearStandard = pearStandardMap[pear_standard_id];

        return {
          accuracy: Math.round(accuracy * 100),
          standard,
          pearStandard,
        };
      });
  }, [accuracyFetch.data, standardsFetch.data]);

  const isLoading = accuracyFetch.isLoading || standardsFetch.isLoading;

  return (
    <>
      {isLoading ? (
        <Box mt={pxToRem(20)}>
          <LoadingSpinner />
        </Box>
      ) : (
        <AdminStandardsTab
          standardsData={standardsData}
          hasNoPremiumAccess={hasNoPremiumAccess}
        />
      )}
    </>
  );
};
