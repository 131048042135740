import { Standard } from "@goguardian/types-psi";
import { useState } from "react";
import { useMount } from "react-use";

import { usePear } from "links/lib/features/pear";

export interface IUseSelectStandardsModalOpts {
  initialStandardIds?: Array<string>;
}

export interface IUseSelectStandardsModalResponse {
  open: () => void;
  removeStandard: (standard: Standard) => void;
  resetStandards: () => void;
  isLoading: boolean;
  selectedStandards: Array<Standard>;
}

type UseSelectStandardsModalState = {
  selectedStandards: Array<Standard>;
  isLoading: boolean;
};

export default function useSelectStandardsModal({
  initialStandardIds,
}: IUseSelectStandardsModalOpts): IUseSelectStandardsModalResponse {
  const pear = usePear();

  const [state, setState] = useState<UseSelectStandardsModalState>({
    selectedStandards: [],
    isLoading: true,
  });

  const loadInitialStandards = async () => {
    if (!initialStandardIds || initialStandardIds.length === 0) {
      setState((state) => {
        return {
          ...state,
          isLoading: false,
        };
      });
      return;
    }

    const initialStandards = await pear.listStandards(initialStandardIds);
    setState((state) => {
      return {
        ...state,
        selectedStandards: initialStandards,
        isLoading: false,
      };
    });
  };

  useMount(() => {
    loadInitialStandards();
  });

  const open = async () => {
    const selectedStandardIds = state.selectedStandards.map(
      (standard: { id: string }) => {
        return standard.id;
      }
    );

    const res = await pear.openStandardsModal({
      standardIds: selectedStandardIds,
    });
    if (res) {
      setState((state) => {
        return {
          ...state,
          selectedStandards: res.standards,
        };
      });
    }
  };

  const removeStandard = (standard: Standard) => {
    setState((state) => {
      return {
        ...state,
        selectedStandards: state.selectedStandards.filter(
          (s) => s.id != standard.id
        ),
      };
    });
  };

  const resetStandards = () => {
    setState((state) => {
      return { ...state, selectedStandards: [] };
    });
  };

  return {
    open,
    removeStandard,
    resetStandards,
    isLoading: state.isLoading,
    selectedStandards: state.selectedStandards,
  };
}
