import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import {
  IPracticeSet,
  IPracticeSetItem,
  ISubjectCount,
  PracticeDataDocumentType,
  PracticeDataSortBy,
  PracticeDataSortDir,
  PracticeSetAvailability,
} from "../../types";
import { usePear } from "../pear";

export interface ITermParsedSearchFilters {
  grade_level_ids: Array<string>;
  subject_ids: Array<string>;
}

export interface ISearchPracticeDataResponse {
  practice_sets: Array<IPracticeSet>;
  practice_set_items: Array<IPracticeSetItem>;
  subject_counts: Array<ISubjectCount>;
  total_count: number;
  page: number;
  per_page: number;
  suggestions: Array<string>;
  term_parsed_search_filters: ITermParsedSearchFilters;
}

export interface IUseSearchPracticeDataArgs {
  document_type?: PracticeDataDocumentType;
  term?: string;
  standard_ids?: Array<string>;
  subject_ids?: Array<string>;
  grade_level_ids?: Array<string>;
  region?: string;
  page?: number;
  per_page?: number;
  include_shared: boolean;
  // -1 for no collection, 0 for no preference (default), >0 for filter by specific collection
  collection_id?: string;
  disabled?: boolean;
  certified_only?: boolean;
  hide_premium?: boolean;
  exclude_certified?: boolean;
  sort_by?: PracticeDataSortBy;
  sort_dir?: PracticeDataSortDir;
  availability: PracticeSetAvailability;
  anonymous?: boolean;
  exclude_user_id?: string;
  exclude_public_availability_from_domain_search?: boolean;
}

export default function useSearchPracticeData(
  args: IUseSearchPracticeDataArgs
): UseQueryResult<ISearchPracticeDataResponse> {
  const axios = useAxios();
  const pear = usePear();

  const { disabled, anonymous, ...params } = args;
  const { include_shared, document_type, availability } = params;

  const query = useQuery<ISearchPracticeDataResponse>(
    [
      "searchPracticeData",
      { include_shared, document_type, availability },
      params,
    ],
    async (): Promise<ISearchPracticeDataResponse> => {
      const response = await axios
        .get(`/v1/search/practice-data${anonymous ? "/anon" : ""}`, {
          params,
        })
        .then((r) => r.data as ISearchPracticeDataResponse);

      await pear.populatePracticeSetsStandards(response.practice_sets);
      await pear.populatePracticeSetItemsStandards(response.practice_set_items);

      return response;
    },
    { enabled: !disabled }
  );

  return query;
}
