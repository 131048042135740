import React, { useCallback, useMemo } from "react";

import { RolePickerModal } from "adminComponents/molecules/RolePickerModal";
import { TeacherHomepageLibrary } from "adminComponents/screens/TeacherHomepageLibrary";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import { useFetchRecommendedPracticeSets } from "links/lib/features/practiceSets";
import { UserRole } from "links/lib/types";
import HomeRedirect from "screens/App/components/HomeRedirect";
import { useGuestTeacherNavigationData } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";

export const UninitializedExplore: React.FC = () => {
  const { authUser, initializeUserRole, authRequiredAgreements } = useAuth();
  const navigationData = useGuestTeacherNavigationData();

  // TODO any events we want to track?
  // const { trackEvent } = useAnalytics();

  const fetchRecommendedPracticeSets = useFetchRecommendedPracticeSets({
    periodDays: 30,
    limit: 18,
  });
  useErrorToast(fetchRecommendedPracticeSets.error);

  const acceptedAgreementIds = useMemo(
    () => authRequiredAgreements?.map((agreement) => agreement.id),
    [authRequiredAgreements]
  );

  const handleChooseRole = useCallback(
    async (role: UserRole) => {
      await initializeUserRole(role === UserRole.Student, acceptedAgreementIds);
    },
    [initializeUserRole, acceptedAgreementIds]
  );

  if (authUser?.role !== UserRole.Uninitialized) {
    return <HomeRedirect />;
  }

  const filters = {
    subjectIds: [],
    standardIds: [],
    pearStandardIds: [],
    gradeLevelIds: [],
    numQuestions: [],
    languages: [],
    certifiedOnly: false,
    hidePremium: false,
  };

  return (
    <>
      <RolePickerModal
        handleChooseRole={handleChooseRole}
        handleClose={() => {
          /** cannot close */
        }}
        isOpen
        agreements={authRequiredAgreements || []}
      />
      <TeacherHomepageLibrary
        isGuest
        navigationData={navigationData}
        subjectCounts={[]}
        popularPracticeSets={
          fetchRecommendedPracticeSets.data?.practice_sets || []
        }
        query={{ filters }}
        suggestions={[]}
        isLoading={false}
        isSearching={false}
        handleSearch={() => {
          /* do nothing*/
        }}
        handleSuggest={() => {
          /* do nothing*/
        }}
        handleSetSearchTerm={() => {
          /* do nothing*/
        }}
        handleClearSearchInput={() => {
          /* do nothing*/
        }}
        handleSelectSubjects={() => {
          /* do nothing*/
        }}
        handleSelectGrades={() => {
          /* do nothing*/
        }}
        handleGenerateSet={() => {
          /* do nothing*/
        }}
        handleOpenFilterFlyout={() => {
          /* do nothing*/
        }}
        handlePracticeSetPreview={() => {
          /* do nothing*/
        }}
      />
    </>
  );
};
