import { Standard } from "@goguardian/types-psi";
import { UseQueryResult, useQuery } from "react-query";

import { usePearStandardsEnabled } from "adminComponents/utils/usePearStandardsEnabled";

import { useAxios } from "../../hooks/useAxios";
import type { IStandard } from "../../types";
import { usePear } from "../pear";

export interface IFetchStandardsOpts {
  limit: number;
  offset: number;
  country?: string;
  grade_level_id?: string;
  subject_id?: string;
  standard_collection_id?: string;
  region?: string;
  query?: string;
  ids?: Array<string>;
  enabled: boolean;
  disablePearStandards?: boolean;
}

export interface IFetchStandardsResponse {
  standards: Array<IStandard>;
  pear_standards?: Array<Standard>;
}

/**
 * Fetches a list of standards
 * @returns
 */
export default function useFetchStandards(
  opts: IFetchStandardsOpts
): UseQueryResult<IFetchStandardsResponse, unknown> {
  const { enabled, disablePearStandards, ...params } = opts;
  const pear = usePear();
  const pearStandardsEnabled = usePearStandardsEnabled();
  const axios = useAxios();

  const q = useQuery<IFetchStandardsResponse>(
    ["standards", { params }],
    async (): Promise<IFetchStandardsResponse> => {
      const response = await axios
        .get(`/v1/standards`, {
          params,
        })
        .then((r) => r.data as IFetchStandardsResponse);

      if (pearStandardsEnabled && !disablePearStandards) {
        response.pear_standards = await pear.listStandards(
          response.standards.map((s) => s.pear_standard_id)
        );
      }

      return response;
    },
    { enabled }
  );

  return q;
}
