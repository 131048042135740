import { IPracticeSet } from "links/lib/types";

import { usePearStandardsEnabled } from "./usePearStandardsEnabled";

export const usePracticeSetStandardLabels = (
  practiceSet?: IPracticeSet
): Array<string> => {
  const pearStandardsEnabled = usePearStandardsEnabled();

  if (!practiceSet) return [];

  if (pearStandardsEnabled) {
    return (practiceSet.pear_standards || []).map((s) => s.root_label);
  }

  return practiceSet.practice_set_item_standard_labels || [];
};
