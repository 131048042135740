import { Box, Flex, Show } from "@chakra-ui/react";
import { Standard } from "@goguardian/types-psi";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { usePearStandardsEnabled } from "adminComponents/utils/usePearStandardsEnabled";
import { IStandard } from "links/lib/types";

import { PieChartWithLabel } from "../PieChartWithLabel";

export interface IProps {
  accuracy?: number;
  accuracyType?: "class" | "student" | "personal";
  standard: IStandard;
  pearStandard?: Standard;
  hasNoPremiumAccess?: boolean;
}

export const StandardsCard: React.FC<IProps> = ({
  accuracy,
  accuracyType,
  standard,
  pearStandard,
  hasNoPremiumAccess,
}) => {
  const pearStandardsEnabled = usePearStandardsEnabled();
  const { t } = useTranslation("admin", { useSuspense: false });

  const shouldDisplayAccuracy = typeof accuracy !== "undefined" && accuracyType;
  const accuracyTypeLabel =
    accuracyType === "class"
      ? t("standardsCard.classAccuracy")
      : accuracyType === "student"
      ? t("standardsCard.studentAccuracy")
      : t("common.accuracy");

  const pieChartWithLabel = (
    <PieChartWithLabel
      percentage={accuracy}
      text={accuracyTypeLabel}
      hasNoPremiumAccess={hasNoPremiumAccess}
    />
  );

  const displayStandard = pearStandardsEnabled ? pearStandard : standard;

  if (!displayStandard) return null;

  return (
    <Card
      borderColor="primary.tan"
      backgroundColor="primary.white"
      variant="adminCardSmallBorderSmallPadding"
    >
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        marginBottom={[pxToRem(16), null, pxToRem(20)]}
      >
        <Heading as="p" variant="adminH6">
          {displayStandard.root_label || displayStandard.label}
        </Heading>
        {shouldDisplayAccuracy && <Show above="md">{pieChartWithLabel}</Show>}
      </Flex>
      <Text color="primary.warm-black" variant="adminP1">
        {displayStandard.description}
      </Text>
      {shouldDisplayAccuracy && (
        <Show below="md">
          <Box marginY={pxToRem(16)}>
            <Divider color="primary.light-gray" />
          </Box>
          {pieChartWithLabel}
        </Show>
      )}
    </Card>
  );
};
