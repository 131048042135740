/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as fm from "../fetch.pb"


export enum ThirdParty {
  INVALID_THIRD_PARTY = "INVALID_THIRD_PARTY",
  CLEVER = "CLEVER",
  GOOGLE = "GOOGLE",
  MICROSOFT = "MICROSOFT",
}

export enum Product {
  INVALID_PRODUCT = "INVALID_PRODUCT",
  EDULASTIC = "EDULASTIC",
  GIANT_STEPS = "GIANT_STEPS",
  PEAR_DECK = "PEAR_DECK",
  TUTOR_ME = "TUTOR_ME",
  ASSISTANT = "ASSISTANT",
}

export type UpsertUserInternalRequest = {
  user: User | null
}

export type UpsertUserInternalResponse = {
  user: User | null
}

export type GetUserInternalRequest = {
  user_id: string
}

export type GetUserInternalResponse = {
  user: User | null
  user_products: UserProduct[] | null
}

export type UserProduct = {
  product: Product | null
  product_user_id: string
}

/**
 * Note that this message will be deprecated in favor of the one in pear-roster/roster.proto
 */
export type User = {
  id: string
  email: string
  given_name: string
  middle_name: string
  family_name: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type ProductAccessData = {
  product: Product | null
  has_user: boolean
  has_license: boolean
  can_create_user: boolean
  redirect_url: string
  product_user_id: string
}

export type GetUserRequest = {
}

export type GetUserResponse = {
  user: User | null
  product_access_data: ProductAccessData[] | null
}

export type UpsertUserThirdPartyTokenInternalRequest = {
  token: UserThirdPartyToken | null
}

export type UpsertUserThirdPartyTokenInternalResponse = {
  token: UserThirdPartyToken | null
}

export type UserThirdPartyToken = {
  id: string
  user_id: string
  third_party: ThirdParty | null
  foreign_id: string
  oauth_access_token: string
  oauth_refresh_token: string
  oauth_access_token_expiration: string | null
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type ListUserThirdPartyTokensInternalRequest = {
  user_id: string
}

export type ListUserThirdPartyTokensInternalResponse = {
  tokens: UserThirdPartyToken[] | null
}

export type InitializeUserProductRequest = {
  target_product: Product | null
}

export type InitializeUserProductResponse = {
  redirect_url: string
}

export class PearUserManagement {
  static GetUser(req: GetUserRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<GetUserResponse>> {
    return fm.fetchReq<GetUserRequest, GetUserResponse>(`/pearusermanagement.PearUserManagement/GetUser`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertUserInternal(req: UpsertUserInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<UpsertUserInternalResponse>> {
    return fm.fetchReq<UpsertUserInternalRequest, UpsertUserInternalResponse>(`/pearusermanagement.PearUserManagement/UpsertUserInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetUserInternal(req: GetUserInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<GetUserInternalResponse>> {
    return fm.fetchReq<GetUserInternalRequest, GetUserInternalResponse>(`/pearusermanagement.PearUserManagement/GetUserInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListUserThirdPartyTokensInternal(req: ListUserThirdPartyTokensInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<ListUserThirdPartyTokensInternalResponse>> {
    return fm.fetchReq<ListUserThirdPartyTokensInternalRequest, ListUserThirdPartyTokensInternalResponse>(`/pearusermanagement.PearUserManagement/ListUserThirdPartyTokensInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertUserThirdPartyTokenInternal(req: UpsertUserThirdPartyTokenInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<UpsertUserThirdPartyTokenInternalResponse>> {
    return fm.fetchReq<UpsertUserThirdPartyTokenInternalRequest, UpsertUserThirdPartyTokenInternalResponse>(`/pearusermanagement.PearUserManagement/UpsertUserThirdPartyTokenInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static InitializeUserProduct(req: InitializeUserProductRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<InitializeUserProductResponse>> {
    return fm.fetchReq<InitializeUserProductRequest, InitializeUserProductResponse>(`/pearusermanagement.PearUserManagement/InitializeUserProduct`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}

