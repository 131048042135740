import { CreateClassificationTabForm } from "adminComponents/molecules/CreateQuestionClassificationTab";
import { CreateDiagramTabForm } from "adminComponents/molecules/CreateQuestionDiagramTab";
import { CreateDrawTabForm } from "adminComponents/molecules/CreateQuestionDrawTab";
import { CreateFeedbackTabForm } from "adminComponents/molecules/CreateQuestionFeedbackTab";
import { CreateInternalTabForm } from "adminComponents/molecules/CreateQuestionInternalTab";
import { CreateMultipleChoiceTabForm } from "adminComponents/molecules/CreateQuestionMultipleChoiceTab";
import { CreateNumberResponseTabForm } from "adminComponents/molecules/CreateQuestionNumberResponseTab";
import { CreateStandardTabForm } from "adminComponents/molecules/CreateQuestionStandardsTab";
import { CreateTermTabForm } from "adminComponents/molecules/CreateQuestionTermTab";
import { CreateTextResponseTabForm } from "adminComponents/molecules/CreateQuestionTextResponseTab";
import { convertToSlate } from "lib/slateUtils";
import { IPracticeSetItem, QuestionType, RichValue } from "links/lib/types";
import { generateEmptySlateState } from "links/lib/util";

export type CreateQuestionFormDataType = {
  term?: CreateTermTabForm;
  multipleChoice?: CreateMultipleChoiceTabForm;
  textResponse?: CreateTextResponseTabForm;
  numberResponse?: CreateNumberResponseTabForm;
  diagram?: CreateDiagramTabForm;
  draw?: CreateDrawTabForm;
  classify?: CreateClassificationTabForm;
  standard?: CreateStandardTabForm;
  internal?: CreateInternalTabForm;
  feedback?: CreateFeedbackTabForm;
};

export const getCreateQuestionFormData = (
  flyoutType: string,
  defaultValue?: IPracticeSetItem,
  isInternalUser?: boolean
): CreateQuestionFormDataType => {
  return defaultValue
    ? {
        term:
          flyoutType === QuestionType.TermDefinition
            ? {
                alternateSpellings: defaultValue.alternate_text_responses.map(
                  (value) => value.alternate_text
                ),
                definition: defaultValue.term_definition?.definition ?? "",
                term: defaultValue.term_definition?.term ?? "",
                imageAltText: defaultValue.image_alt_text,
                imageUrl: defaultValue.image_url,
                audioUrl: defaultValue.audio_url,
                videoUrl: defaultValue.video_url,
                requiresHigherOrderThinking:
                  defaultValue.is_higher_order_thinking,
              }
            : undefined,
        feedback: {
          feedback: convertToSlate(defaultValue.feedback ?? "") as RichValue,
          imageAltText: defaultValue.feedback_image_alt_text ?? "",
          imageUrl: defaultValue.feedback_image_url ?? "",
        },
        internal: {
          cncCode: defaultValue.cnc_code ?? "",
          isCertified: defaultValue.is_certified ?? false,
          isPremium: defaultValue.is_premium ?? false,
          skill: defaultValue.skill ?? null,
        },
        multipleChoice:
          flyoutType === QuestionType.MultipleChoice ||
          flyoutType === QuestionType.MultipleSelect
            ? {
                questionType: flyoutType,
                imageAltText: defaultValue.image_alt_text,
                imageUrl: defaultValue.image_url,
                audioUrl: defaultValue.audio_url,
                videoUrl: defaultValue.video_url,
                requiresHigherOrderThinking:
                  defaultValue.is_higher_order_thinking,
                prompt: convertToSlate(
                  (flyoutType === QuestionType.MultipleChoice
                    ? defaultValue.multiple_choice?.prompt
                    : defaultValue.multiple_select?.prompt) ?? ""
                ) as RichValue,
                responses:
                  flyoutType === QuestionType.MultipleChoice
                    ? defaultValue.multiple_choice?.choices.map((choice) => ({
                        id: choice.id,
                        isCorrect: choice.is_correct,
                        value: convertToSlate(choice.text ?? "") as RichValue,
                        imageUrl: choice.image_url,
                        imageAltText: choice.image_alt_text,
                      })) ?? []
                    : defaultValue.multiple_select?.choices.map((choice) => ({
                        id: choice.id,
                        isCorrect: choice.is_correct,
                        value: convertToSlate(choice.text ?? "") as RichValue,
                        imageUrl: choice.image_url,
                        imageAltText: choice.image_alt_text,
                        uid: choice.id as string,
                      })) ?? [],
              }
            : undefined,
        standard: {
          standards: defaultValue.standards ?? [],
          pearStandards: defaultValue.pear_standards ?? [],
        },
        textResponse:
          flyoutType === QuestionType.TextResponse
            ? {
                alternateCorrectResponses:
                  defaultValue.alternate_text_responses.map((response) => ({
                    response: response.alternate_text,
                  })),
                correctResponse:
                  defaultValue.text_response?.correct_response ?? "",
                enablePartialCredit:
                  defaultValue.text_response?.enable_partial_credit ?? false,
                imageAltText: defaultValue.image_alt_text,
                imageUrl: defaultValue.image_url,
                audioUrl: defaultValue.audio_url,
                videoUrl: defaultValue.video_url,
                prompt: convertToSlate(
                  defaultValue.text_response?.prompt ?? ""
                ) as RichValue,
                requiresHigherOrderThinking:
                  defaultValue.is_higher_order_thinking,
              }
            : undefined,
        numberResponse:
          flyoutType === QuestionType.NumberResponse
            ? {
                alternateCorrectResponses:
                  defaultValue.alternate_text_responses.map((response) => ({
                    response: response.alternate_text,
                  })),
                correctResponse:
                  defaultValue.number_response?.correct_response ?? "",
                prefix: convertToSlate(
                  defaultValue.number_response?.prefix ?? ""
                ) as RichValue,
                suffix: convertToSlate(
                  defaultValue.number_response?.suffix ?? ""
                ) as RichValue,
                imageAltText: defaultValue.image_alt_text,
                imageUrl: defaultValue.image_url,
                audioUrl: defaultValue.audio_url,
                videoUrl: defaultValue.video_url,
                prompt: convertToSlate(
                  defaultValue.number_response?.prompt ?? ""
                ) as RichValue,
                requiresHigherOrderThinking:
                  defaultValue.is_higher_order_thinking,
              }
            : undefined,
        diagram:
          flyoutType === QuestionType.Diagram
            ? {
                imageAltText: defaultValue.image_alt_text,
                imageUrl: defaultValue.image_url,
                prompt: convertToSlate(
                  defaultValue.diagram?.prompt ?? ""
                ) as RichValue,
                labels: defaultValue.diagram_labels ?? [],
                requiresHigherOrderThinking:
                  defaultValue.is_higher_order_thinking,
              }
            : undefined,
        draw:
          flyoutType === QuestionType.Draw
            ? {
                imageAltText: defaultValue.image_alt_text,
                imageUrl: defaultValue.image_url,
                audioUrl: defaultValue.audio_url,
                videoUrl: defaultValue.video_url,
                prompt: convertToSlate(
                  defaultValue.draw?.prompt ?? ""
                ) as RichValue,
                requiresHigherOrderThinking:
                  defaultValue.is_higher_order_thinking,
                backgroundImageUrl:
                  defaultValue.draw?.background_image_url || "",
              }
            : undefined,
        classify:
          flyoutType === QuestionType.Classify
            ? defaultValue
              ? {
                  imageAltText: defaultValue.image_alt_text,
                  imageUrl: defaultValue.image_url,
                  audioUrl: defaultValue.audio_url,
                  videoUrl: defaultValue.video_url,
                  prompt: convertToSlate(
                    defaultValue.classify?.prompt ?? ""
                  ) as RichValue,
                  categories:
                    defaultValue.classify?.categories.map((c) => {
                      return {
                        ...c,
                        text: convertToSlate(c.text ?? "") as RichValue,
                        choices: c.choices.map((choice) => {
                          return {
                            ...choice,
                            text: convertToSlate(
                              choice.text ?? ""
                            ) as RichValue,
                          };
                        }),
                      };
                    }) ?? [],
                  requiresHigherOrderThinking:
                    defaultValue.is_higher_order_thinking,
                }
              : undefined
            : undefined,
      }
    : {
        standard: {
          standards: [],
          pearStandards: [],
        },
        feedback: {
          feedback: generateEmptySlateState(),
          imageAltText: "",
          imageUrl: "",
        },
        internal: {
          cncCode: "",
          isCertified: !!isInternalUser,
          isPremium: !!isInternalUser,
          skill: null,
        },
      };
};
