import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { usePrevious } from "react-use";

import { IFilterPracticeSetState } from "adminComponents/organisms/FilterPracticeSetFlyout";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAnalytics } from "lib/contexts/analytics";
import { useLibraryFilter } from "lib/hooks/useLibraryFilter";
import { useFetchCollections } from "links/lib/features/collections";
import {
  useSearchPracticeData,
  useSearchPracticeDataInfinite,
  useSearchPracticeDataOnce,
} from "links/lib/features/search";
import {
  AnalyticsEvent,
  FilterCategory,
  ICollection,
  IGradeLevel,
  IPracticeSet,
  ISubject,
  ISubjectCount,
  PracticeDataDocumentType,
  PracticeDataSortBy,
  PracticeDataSortDir,
  PracticeSetAvailability,
} from "links/lib/types";

interface ISearchQuery {
  term?: string;
  filters: IFilterPracticeSetState;
}

export interface IArgs {
  userId?: string;
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  country: string;
  region?: string;
  availability?: PracticeSetAvailability;
  ignoreCollections?: boolean;
  disableRecentPracticeSets?: boolean;
  anonymous?: boolean;
  disabled?: boolean;
}

export interface IResult {
  query: ISearchQuery;
  handleSetTerm: (term: string) => void;
  handleClearFilters: () => void;
  handleRemoveFilterTag: (category: FilterCategory, id: string) => void;
  handleOpenFilterFlyout: () => void;
  handleViewMoreFolders: () => void;
  handleViewMorePracticeSets: () => void;
  practiceSets: Array<IPracticeSet>;
  recentPracticeSets: Array<IPracticeSet>;
  collections: Array<ICollection>;
  totalCounts: {
    practiceSets: number;
    collections: number;
  };
  isInitialLoad: boolean;
  isSearchLoading: boolean;
  showViewMoreFolders: boolean;
  showViewMorePracticeSets: boolean;
  isSearch: boolean;
  handleClearSearchTerm: () => void;
  filterFlyout: React.ReactElement;
  standardsModal: React.ReactElement;
  // non-filtered collections
  allCollections: Array<ICollection>;
  handleSuggest: (term: string) => void;
  suggestions: Array<string>;
}

// the max number of recent sets to show
const MAX_RECENT_SETS_COUNT = 4;
const PRACTICE_SETS_PAGE_COUNT = 8;
const DEFAULT_COLLECTIONS_MAX = 8;

export const useLibrarySearch = (args: IArgs): IResult => {
  const {
    gradeLevels,
    subjects,
    country,
    region,
    ignoreCollections,
    disableRecentPracticeSets,
    anonymous,
    disabled,
  } = args;

  const [maxCollections, setMaxCollections] = useState(DEFAULT_COLLECTIONS_MAX);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [collectionId, setCollectionId] = useState("-1");
  const { trackEvent } = useAnalytics();
  const [flyoutVals, setFlyoutVals] = useState<{
    isLoading: boolean;
    subjectCounts: Array<ISubjectCount>;
  }>({
    isLoading: true,
    subjectCounts: [],
  });

  const {
    handleClearFilters,
    handleClearSearchTerm,
    handleOpenFilterFlyout,
    handleRemoveFilterTag,
    handleSetTerm,
    filterFlyout,
    standardsModal,
    query,
  } = useLibraryFilter({
    gradeLevels,
    subjects,
    country,
    region,
    isLoading: flyoutVals.isLoading,
    subjectCounts: flyoutVals.subjectCounts,
    disabled: disabled || anonymous,
  });

  const searchParams = {
    user_id: args.userId || "0",
    availability: args.availability,
    term: query.term || "",
    standard_ids: query.filters?.standardIds,
    pear_standard_ids: query.filters?.pearStandardIds,
    subject_ids: query.filters?.subjectIds,
    grade_level_ids: query.filters?.gradeLevelIds,
    include_shared: false,
    document_type: PracticeDataDocumentType.PracticeSets,
    collection_id: collectionId,
    per_page: PRACTICE_SETS_PAGE_COUNT,
    certified_only: query.filters.certifiedOnly,
    hide_premium: query.filters.hidePremium,
    anonymous: anonymous,
    disabled: disabled,
  };

  const searchPracticeSets = useSearchPracticeDataInfinite(searchParams);

  const searchPracticeDataOnce = useSearchPracticeDataOnce({});

  const handleSuggestDebounced = debounce(
    (term: string) => {
      searchPracticeDataOnce.execute({
        ...searchParams,
        term,
        availability:
          searchParams.availability || PracticeSetAvailability.Private,
        include_suggestions: true,
        page: 1,
        per_page: 8,
        exclude_subject_counts: true,
      });
    },
    250,
    {
      leading: false,
      trailing: true,
    }
  );

  const hasPSFilters = (() => {
    return (
      query.filters.certifiedOnly ||
      query.filters.hidePremium ||
      !!query.filters.gradeLevelIds.length ||
      !!query.filters.languages.length ||
      !!query.filters.numQuestions.length ||
      !!query.filters.standardIds.length ||
      !!query.filters.pearStandardIds.length ||
      !!query.filters.subjectIds.length
    );
  })();

  const isSearch = !!query.term || hasPSFilters;

  const fetchCollections = useFetchCollections({ disabled: ignoreCollections });
  const recentPracticeSets = useSearchPracticeData({
    per_page: MAX_RECENT_SETS_COUNT,
    include_shared: false,
    document_type: PracticeDataDocumentType.PracticeSets,
    sort_by: PracticeDataSortBy.CreatedAt,
    sort_dir: PracticeDataSortDir.Desc,
    availability: args.availability || PracticeSetAvailability.Private,
    disabled: disabled || disableRecentPracticeSets,
  });

  useErrorToast(fetchCollections.error);
  useErrorToast(recentPracticeSets.error);

  // Whenever the query changes, reset paging
  useEffect(() => {
    setMaxCollections(DEFAULT_COLLECTIONS_MAX);
  }, [query]);

  useEffect(() => {
    if (
      isInitialLoad &&
      !fetchCollections.isLoading &&
      !searchPracticeSets.isLoading &&
      !recentPracticeSets.isLoading
    ) {
      setIsInitialLoad(false);
    }
  }, [
    isInitialLoad,
    fetchCollections.isLoading,
    searchPracticeSets.isLoading,
    recentPracticeSets.isLoading,
  ]);

  const lastPage = (searchPracticeSets.data?.pages.length || 1) - 1;
  useEffect(() => {
    setFlyoutVals({
      isLoading: searchPracticeSets.isLoading,
      subjectCounts:
        searchPracticeSets.data?.pages[lastPage]?.subject_counts ?? [],
    });
  }, [searchPracticeSets.data?.pages, lastPage, searchPracticeSets.isLoading]);

  useEffect(() => {
    setCollectionId(isSearch || ignoreCollections ? "0" : "-1");
  }, [isSearch, ignoreCollections]);

  const prevQuery = usePrevious(query);
  useEffect(() => {
    if (query !== prevQuery) {
      trackEvent(AnalyticsEvent.TeacherDashboard_MyLibrary_Root_Search, {
        hasTerm: !!query.term,
        hasStandards:
          !!query.filters.standardIds.length ||
          !!query.filters.pearStandardIds.length,
        hasSubjects: !!query.filters.subjectIds.length,
        hasGrades: !!query.filters.gradeLevelIds.length,
        hasCertifiedOnly: query.filters.certifiedOnly,
        hasHidePremium: query.filters.hidePremium,
      });
    }
  }, [query, prevQuery, trackEvent]);

  const matchedCollections = (() => {
    if (hasPSFilters) return [];

    let cs = fetchCollections.data?.collections || [];

    if (query.term) {
      const term = query.term?.toLowerCase();

      cs = cs.filter((c) => {
        return c.name.toLowerCase().indexOf(term) !== -1;
      });
    }

    return cs;
  })();

  const handleViewMoreFolders = () => {
    setMaxCollections((val) => val + DEFAULT_COLLECTIONS_MAX);

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_Root_ViewMoreFolders,
      {}
    );
  };

  const handleViewMorePracticeSets = () => {
    searchPracticeSets.fetchNextPage();

    trackEvent(
      AnalyticsEvent.TeacherDashboard_MyLibrary_Root_ViewMorePracticeSets,
      {}
    );
  };

  const totalPracticeSets =
    searchPracticeSets.data?.pages[lastPage]?.total_count ?? 0;

  const practiceSets =
    searchPracticeSets.data?.pages.flatMap((p) => p.practice_sets) || [];

  // calculate how many practice sets we should be showing to the user.
  // relying on practiceSets.count creates unwanted behavior when sets are deleted
  const showingPracticeSets =
    (searchPracticeSets.data?.pages.length ?? 1) * PRACTICE_SETS_PAGE_COUNT;

  const allCollections = fetchCollections.data?.collections || [];

  return {
    handleSetTerm,
    handleClearFilters,
    handleRemoveFilterTag,
    handleOpenFilterFlyout,
    query,
    filterFlyout,
    handleViewMoreFolders,
    handleViewMorePracticeSets,
    collections: matchedCollections.slice(0, maxCollections),
    practiceSets,
    recentPracticeSets: recentPracticeSets.data?.practice_sets || [],
    totalCounts: {
      collections: matchedCollections.length,
      practiceSets: totalPracticeSets,
    },
    isInitialLoad,
    isSearchLoading: searchPracticeSets.isLoading,
    showViewMoreFolders: maxCollections < matchedCollections.length,
    showViewMorePracticeSets: showingPracticeSets < totalPracticeSets,
    isSearch,
    handleClearSearchTerm,
    standardsModal,
    allCollections,
    handleSuggest: handleSuggestDebounced,
    suggestions: searchPracticeDataOnce.data?.suggestions || [],
  };
};
