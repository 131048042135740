import { Standard } from "@goguardian/types-psi";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import { useErrorToast } from "adminComponents/utils/toast";
import {
  IHandleSetQueryArgs,
  ISearchQuery,
  useLibraryFilter,
} from "lib/hooks/useLibraryFilter";
import {
  useSearchPracticeData,
  useSearchPracticeDataOnce,
} from "links/lib/features/search";
import {
  FilterCategory,
  IGradeLevel,
  IPracticeSet,
  ISearchSuggestion,
  IStandard,
  ISubject,
  ISubjectCount,
  PracticeDataDocumentType,
  PracticeSetAvailability,
} from "links/lib/types";

export interface IArgs {
  gradeLevels: Array<IGradeLevel>;
  subjects: Array<ISubject>;
  country: string;
  region?: string;
  // Should be used when distinguishExpertAndCommunity is false,
  // which means that a single search is performed.
  basicSearchPage?: number;
  // expertPage and communityPage should be passed if
  // distinguishExpertAndCommunity is true.
  expertPage?: number;
  communityPage?: number;
  perPage: number;
  initialQuery?: ISearchQuery;
  availability: PracticeSetAvailability;
  showPremiumFilter?: boolean;
  showSubjectsCounts?: boolean;
  disabled?: boolean;
  distinguishExpertAndCommunity?: boolean;
}

export interface IResult {
  query: ISearchQuery;
  handleSetTerm: (term: string) => void;
  handleClearFilters: () => void;
  handleRemoveFilterTag: (category: FilterCategory, id: string) => void;
  handleOpenFilterFlyout: () => void;
  practiceSets: {
    total: Array<IPracticeSet>;
    expert: Array<IPracticeSet>;
    community: Array<IPracticeSet>;
  };
  totalCounts: {
    practiceSets: {
      total: number;
      expert: number;
      community: number;
    };
  };
  isInitialLoad: boolean;
  isSearchLoading: boolean;
  handleClearSearchTerm: () => void;
  handleChangeSubjects: (subjectIds: Array<string>) => void;
  handleChangeGradeLevels: (gradeLevelIds: Array<string>) => void;
  filterFlyout: React.ReactElement;
  standards: Array<IStandard>;
  pearStandards: Array<Standard>;
  standardsModal: React.ReactElement;
  subjectCounts: Array<ISubjectCount>;
  handleOpenStandardsModal: (isDirectSearch?: boolean) => void;
  handleSuggest: (term: string) => void;
  suggestions: Array<string>;
  handleUpdateQuery: (args: IHandleSetQueryArgs) => void;
  searchFilterSuggestions: Array<ISearchSuggestion>;
  isTermParsedSearchFilters: boolean;
}

export const usePublicLibrarySearch = (args: IArgs): IResult => {
  const {
    gradeLevels,
    subjects,
    country,
    region,
    basicSearchPage,
    expertPage,
    communityPage,
    perPage,
    initialQuery,
    availability,
    showPremiumFilter = true,
    showSubjectsCounts = true,
    disabled = false,
    distinguishExpertAndCommunity = false,
  } = args;

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [flyoutVals, setFlyoutVals] = useState<{
    isLoading: boolean;
    subjectCounts: Array<ISubjectCount>;
  }>({
    isLoading: true,
    subjectCounts: [],
  });

  const {
    handleChangeSubjects,
    handleChangeGradeLevels,
    handleClearFilters,
    handleClearSearchTerm,
    handleOpenFilterFlyout,
    handleOpenStandardsModal,
    handleRemoveFilterTag,
    handleSetTerm,
    filterFlyout,
    query,
    standards,
    pearStandards,
    standardsModal,
    handleUpdateQuery,
  } = useLibraryFilter({
    disabled,
    isLoading: flyoutVals.isLoading,
    subjectCounts: flyoutVals.subjectCounts,
    gradeLevels,
    country,
    region,
    subjects,
    initialQuery,
    showPremiumFilter,
    showSubjectsCounts,
  });

  const searchParams = {
    term: query.term || "",
    standard_ids: query.filters?.standardIds,
    pear_standard_ids: query.filters?.pearStandardIds,
    subject_ids: query.filters?.subjectIds,
    grade_level_ids: query.filters?.gradeLevelIds,
    include_shared: true,
    availability: availability,
    document_type: PracticeDataDocumentType.PracticeSets,
    page: basicSearchPage,
    per_page: perPage,
    certified_only: query.filters.certifiedOnly,
    hide_premium: query.filters.hidePremium,
    disabled,
  };

  const searchPracticeSets = useSearchPracticeData({
    ...searchParams,
    disabled: disabled || distinguishExpertAndCommunity,
  });
  const searchPracticeSetsExpert = useSearchPracticeData({
    ...searchParams,
    page: expertPage,
    certified_only: true,
    disabled: disabled || !distinguishExpertAndCommunity,
  });
  const searchPracticeSetsCommunity = useSearchPracticeData({
    ...searchParams,
    page: communityPage,
    certified_only: false,
    hide_premium: true,
    exclude_certified: true,
    disabled: disabled || !distinguishExpertAndCommunity,
  });

  const isTermParsedSearchFilters =
    !!searchPracticeSetsExpert.data?.term_parsed_search_filters?.grade_level_ids
      ?.length ||
    !!searchPracticeSetsExpert.data?.term_parsed_search_filters?.subject_ids
      ?.length;

  const searchPracticeDataOnce = useSearchPracticeDataOnce({});

  const handleSuggestDebounced = debounce(
    (term: string) => {
      searchPracticeDataOnce.execute({
        ...searchParams,
        term,
        include_suggestions: true,
        include_detailed_suggestions: true,
        page: 1,
        per_page: 8,
        exclude_subject_counts: true,
      });
    },
    250,
    {
      leading: false,
      trailing: true,
    }
  );

  const subjectCounts: Array<ISubjectCount> = useMemo(() => {
    const subjectCountsPublic = searchPracticeSets.data?.subject_counts || [];
    const subjectCountsExpert =
      searchPracticeSetsExpert.data?.subject_counts || [];
    const subjectCountsCommunity =
      searchPracticeSetsCommunity.data?.subject_counts || [];

    const subjectCountsMap: { [key: string]: ISubjectCount } = {};

    subjectCountsPublic
      ?.concat(subjectCountsExpert)
      .concat(subjectCountsCommunity)
      .forEach((subjectCount) => {
        const existingCount = subjectCountsMap[subjectCount.subject_id];
        if (existingCount) {
          subjectCountsMap[subjectCount.subject_id] = {
            ...existingCount,
            count: existingCount.count + subjectCount.count,
          };
        } else {
          subjectCountsMap[subjectCount.subject_id] = subjectCount;
        }
      });

    return Object.values(subjectCountsMap);
  }, [
    searchPracticeSets.data?.subject_counts,
    searchPracticeSetsCommunity.data?.subject_counts,
    searchPracticeSetsExpert.data?.subject_counts,
  ]);

  useErrorToast(searchPracticeSets.error);
  useErrorToast(searchPracticeSetsExpert.error);
  useErrorToast(searchPracticeSetsCommunity.error);

  useEffect(() => {
    if (
      isInitialLoad &&
      !(
        searchPracticeSets.isLoading ||
        searchPracticeSetsExpert.isLoading ||
        searchPracticeSetsCommunity.isLoading
      )
    ) {
      setIsInitialLoad(false);
    }
  }, [
    isInitialLoad,
    searchPracticeSets.isLoading,
    searchPracticeSetsExpert.isLoading,
    searchPracticeSetsCommunity.isLoading,
  ]);

  useEffect(() => {
    setFlyoutVals({
      isLoading: searchPracticeSets.isLoading,
      subjectCounts,
    });
  }, [subjectCounts, searchPracticeSets.isLoading]);

  const totalCounts = {
    practiceSets: {
      total: distinguishExpertAndCommunity
        ? (searchPracticeSetsExpert.data?.total_count || 0) +
          (searchPracticeSetsCommunity.data?.total_count || 0)
        : searchPracticeSets.data?.total_count || 0,
      expert: searchPracticeSetsExpert.data?.total_count || 0,
      community: searchPracticeSetsCommunity.data?.total_count || 0,
    },
  };

  return {
    handleSetTerm,
    handleClearFilters,
    handleRemoveFilterTag,
    handleOpenFilterFlyout,
    query,
    filterFlyout,
    practiceSets: {
      total: distinguishExpertAndCommunity
        ? (searchPracticeSetsExpert.data?.practice_sets || []).concat(
            searchPracticeSetsCommunity.data?.practice_sets || []
          )
        : searchPracticeSets.data?.practice_sets || [],
      expert: searchPracticeSetsExpert.data?.practice_sets || [],
      community: searchPracticeSetsCommunity.data?.practice_sets || [],
    },
    totalCounts,
    isInitialLoad,
    isSearchLoading: searchPracticeSets.isLoading,
    handleClearSearchTerm,
    standards,
    pearStandards,
    standardsModal,
    subjectCounts,
    handleChangeSubjects,
    handleChangeGradeLevels,
    handleOpenStandardsModal,
    handleSuggest: handleSuggestDebounced,
    suggestions: searchPracticeDataOnce.data?.suggestions || [],
    handleUpdateQuery,
    searchFilterSuggestions:
      searchPracticeDataOnce.data?.detailed_suggestions || [],
    isTermParsedSearchFilters,
  };
};
