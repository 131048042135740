import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";

import { useAxios } from "../../hooks/useAxios";
import { IPracticeSet } from "../../types";
import { usePear } from "../pear";

export interface IFetchPracticeSetsResponse {
  practice_sets: Array<IPracticeSet>;
}

export interface IUseFetchPracticeSetsArgs {
  collection_id?: string;
  query?: string;
  include_all?: boolean;
  ids?: Array<string>;
  enabled?: boolean;
  limit?: number;
  include_most_recent_live_practice_timestamp?: boolean;
  include_most_recent_assignment_timestamp?: boolean;
  include_how_to_play_sets?: boolean;
  created_by_user_ids?: Array<string>;
  classroom_id?: string;
}

/**
 * Fetches a list of practice sets
 * for the current user
 * @returns
 */
export default function useFetchPracticeSets(
  args: IUseFetchPracticeSetsArgs
): UseQueryResult<IFetchPracticeSetsResponse, AxiosError> {
  const {
    collection_id = "0",
    query,
    include_all,
    ids,
    enabled = true,
    limit = 100,
    include_most_recent_live_practice_timestamp,
    include_most_recent_assignment_timestamp,
    include_how_to_play_sets,
    created_by_user_ids,
    classroom_id,
  } = args;
  const params = {
    collection_id,
    query,
    ids,
    include_all,
    enabled,
    created_by_user_ids,
  };

  const pear = usePear();
  const axios = useAxios();

  const fetchPracticeSetsResponse = useQuery<
    IFetchPracticeSetsResponse,
    AxiosError
  >(
    ["practiceSets", params],
    async (): Promise<IFetchPracticeSetsResponse> => {
      const response = await axios
        .get("/v1/practice-sets", {
          params: {
            collection_ids: collection_id === "0" ? undefined : collection_id,
            query,
            include_all,
            ids,
            limit,
            include_most_recent_live_practice_timestamp,
            include_most_recent_assignment_timestamp,
            include_how_to_play_sets,
            created_by_user_ids,
            group_id: classroom_id,
          },
        })
        .then((r) => r.data as { practice_sets: Array<IPracticeSet> });

      await pear.populatePracticeSetsStandards(response.practice_sets);

      return {
        practice_sets: response.practice_sets,
      };
    },
    { enabled }
  );

  return fetchPracticeSetsResponse;
}
