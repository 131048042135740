import { Box, Flex, HStack, SimpleGrid } from "@chakra-ui/react";
import { Standard } from "@goguardian/types-psi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { IOption as DropdownOption } from "adminComponents/atoms/Dropdown";
import { Icon } from "adminComponents/atoms/Icon";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { TextLink } from "adminComponents/atoms/TextLink";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { usePearStandardsEnabled } from "adminComponents/utils/usePearStandardsEnabled";
import { emptyStandard } from "links/lib/empty";
import { useSelectStandardsModal } from "links/lib/features/standards";
import { IStandard } from "links/lib/types";

import { CreateQuestionStandardsTabLegacy } from "./CreateQuestionStandardsTabLegacy";

export interface CreateStandardTabForm {
  standards: IStandard[];
  pearStandards: Standard[];
}

export interface CreateQuestionStandardsTabProps {
  states: DropdownOption[];
  regionFilter: string;
  grades: DropdownOption[];
  gradeLevelIdFilter: string;
  subjects: DropdownOption[];
  subjectIdFilter: string;
  standardCollectionIdFilter?: string;
  standardOptions: IStandard[];
  canSubmit: boolean;
  isSubmitting: boolean;
  isLoadingStandards: boolean;
  defaultValue?: CreateStandardTabForm;
  handleChangeFilter: (
    state: string,
    subject: string,
    grade: string,
    standardCollection?: string
  ) => void;
  handleSaveAndClose: (form: CreateStandardTabForm) => void;
  handleChangeForm: (form: CreateStandardTabForm) => void;
  handleSearch: (search: string) => void;
  handleDirty?: () => void;
}
export const CreateQuestionStandardsTab = React.forwardRef<
  HTMLDivElement,
  CreateQuestionStandardsTabProps
>((props, ref) => {
  const pearStandardsEnabled = usePearStandardsEnabled();

  if (pearStandardsEnabled) {
    return <CreateQuestionStandardsTabPear {...props} ref={ref} />;
  }

  // TODO: Deprecate
  return <CreateQuestionStandardsTabLegacy {...props} ref={ref} />;
});

CreateQuestionStandardsTab.displayName = "CreateQuestionStandardsTab";

export const CreateQuestionStandardsTabPear = React.forwardRef<
  HTMLDivElement,
  CreateQuestionStandardsTabProps
>(
  (
    /* eslint-disable react/prop-types */
    {
      handleChangeForm,
      handleSaveAndClose,
      defaultValue,
      canSubmit,
      isSubmitting,
    }: CreateQuestionStandardsTabProps,
    ref
  ) => {
    const { t } = useTranslation("admin", { useSuspense: false });

    const selectStandardsModal = useSelectStandardsModal({
      initialStandardIds: defaultValue?.standards?.map(
        (s) => s.pear_standard_id
      ),
    });

    const [pearStandards, setPearStandards] = useState<Standard[]>([]);
    useEffect(() => {
      setPearStandards(selectStandardsModal.selectedStandards);
      handleChangeForm({
        standards: [],
        pearStandards: selectStandardsModal.selectedStandards,
      });
    }, [selectStandardsModal.selectedStandards, handleChangeForm]);

    const _handleSubmit = () => {
      handleSaveAndClose({
        standards: pearStandards.map((s) => {
          return {
            ...emptyStandard,
            pear_standard_id: s.id,
          };
        }),
        pearStandards,
      });
    };

    return (
      <SimpleGrid
        ref={ref}
        py={pxToRem(40)}
        px={[
          "admin.flyout.mobileXPadding",
          null,
          "admin.flyout.desktopXPadding",
        ]}
        gap={pxToRem(40)}
      >
        <SimpleGrid gap={pxToRem(24)}>
          <Text as="h4" variant="adminP1">
            {t("createQuestion.standardsTitle")}
          </Text>
          <Text as="h4" variant="adminP1">
            {t("createQuestion.standardsTitle2")}
          </Text>
          <Button
            variant="adminButtonOutlined"
            size="lg"
            onClick={selectStandardsModal.open}
          >
            <HStack justifyContent="center">
              <Icon icon="add" iconColor="primary.warm-black" />
              <Text>{t("createQuestion.selectStandards")}</Text>
            </HStack>
          </Button>
        </SimpleGrid>
        {pearStandards.length > 0 && (
          <Flex flexWrap="wrap" mt={pxToRem(8)} gap={pxToRem(12)}>
            {pearStandards.map((standard: Standard) => {
              return (
                <Tag
                  rightIcon="close"
                  key={standard.id}
                  onClick={() => selectStandardsModal.removeStandard(standard)}
                >
                  {standard.root_label}
                </Tag>
              );
            })}
            <Box
              display={["flex", null, "inline-flex"]}
              alignItems="center"
              w={["100%", null, "auto"]}
            >
              <TextLink handleClick={selectStandardsModal.resetStandards}>
                {t("common.clearAll")}
              </TextLink>
            </Box>
          </Flex>
        )}
        <CreateQuestionFooter
          canSubmit={canSubmit}
          isSubmitting={isSubmitting}
          hideHigherOrderThinking
          handleSaveAndClose={_handleSubmit}
        />
      </SimpleGrid>
    );
  }
);

CreateQuestionStandardsTabPear.displayName = "CreateQuestionStandardsTabPear";
