import {
  OpenStandardsModalOptions,
  OpenStandardsModalResponse,
  Standard,
  StandardsSearchModalSearchParams,
} from "@goguardian/types-psi";
import React, { createContext, useState } from "react";

import { usePearStandardsEnabled } from "adminComponents/utils/usePearStandardsEnabled";

import { useAuth } from "../features/auth";
import { IPracticeSet, IPracticeSetItem } from "../types";

export interface IPearState {
  desiredStandardPublicationIds?: Array<string>;
  isStandardsModalOpen: boolean;
  standards: Map<string, Standard>;
  standardsModalSearchParams?: StandardsSearchModalSearchParams;
}

export interface IPearContext {
  isStandardsModalOpen: boolean;
  listStandards(standardIds: Array<string>): Promise<Array<Standard>>;
  openStandardsModal(
    options: OpenStandardsModalOptions
  ): Promise<OpenStandardsModalResponse | undefined>;
  populatePracticeSetItemStandards(
    practiceSetItem: IPracticeSetItem
  ): Promise<void>;
  populatePracticeSetItemsStandards(
    practiceSetItems: Array<IPracticeSetItem>
  ): Promise<void>;
  populatePracticeSetStandards(practiceSet: IPracticeSet): Promise<void>;
  populatePracticeSetsStandards(
    practiceSets: Array<IPracticeSet>
  ): Promise<void>;
}

export const PearContext = createContext<IPearContext>({
  isStandardsModalOpen: false,
  listStandards: async () => {
    return Promise.reject();
  },
  openStandardsModal: () => {
    return Promise.reject();
  },
  populatePracticeSetItemStandards: () => {
    return Promise.reject();
  },
  populatePracticeSetItemsStandards: () => {
    return Promise.reject();
  },
  populatePracticeSetStandards: () => {
    return Promise.reject();
  },
  populatePracticeSetsStandards: () => {
    return Promise.reject();
  },
});

export const PearProvider: React.FC = ({ children }) => {
  const { pear } = window;
  const { authUser } = useAuth();
  const pearStandardsEnabled = usePearStandardsEnabled();

  const [state, setState] = useState<IPearState>({
    desiredStandardPublicationIds: [],
    isStandardsModalOpen: false,
    standards: new Map(),
  });

  const _standardKey = (standardId: string): string => {
    if (!state.desiredStandardPublicationIds) {
      return standardId;
    }
    return `${standardId}-${state.desiredStandardPublicationIds}`;
  };

  const listStandardCrosswalks = async (
    standardIds: Array<string>
  ): Promise<Array<Standard>> => {
    const { standards } = state;

    if (!state.desiredStandardPublicationIds?.length) {
      return listStandards(standardIds);
    }

    const retrieveStandardIds = [
      ...new Set(
        standardIds.filter(
          (standardId) => !standards.has(_standardKey(standardId))
        )
      ),
    ];

    if (retrieveStandardIds.length > 0) {
      const res = await pear.standards.listStandardCrosswalks({
        originStandardIds: retrieveStandardIds,
        destinationStandardPublicationIds: state.desiredStandardPublicationIds,
        perPage: 100,
      });

      Object.keys(res.crosswalk_standards || {}).forEach((originStandardId) => {
        const crosswalkStandards =
          res.crosswalk_standards[originStandardId]?.standards || [];
        // Standards are returned ordered by the provided destination publication IDs.
        // Reverse the results such that the most preferred standard (by publication)
        // takes precedent.
        crosswalkStandards.reverse().map((standard: Standard) => {
          standards.set(_standardKey(originStandardId), {
            ...standard,
            id: originStandardId,
          });
        });
      });

      setState((state: IPearState) => {
        return {
          ...state,
          standards,
        };
      });
    }

    const standardKeys = [
      ...new Set(standardIds.map((standardId) => _standardKey(standardId))),
    ];

    return standardKeys
      .map((standardKey) => standards.get(standardKey))
      .filter((a) => !!a) as Array<Standard>;
  };

  const listStandards = async (
    standardIds: Array<string>
  ): Promise<Array<Standard>> => {
    if (!pearStandardsEnabled) {
      return Promise.resolve([]);
    }

    const { standards } = state;

    if (state.desiredStandardPublicationIds?.length) {
      return listStandardCrosswalks(standardIds);
    }

    const retrieveStandardIds = [
      ...new Set(
        standardIds.filter(
          (standardId) => !standards.has(_standardKey(standardId))
        )
      ),
    ];

    if (retrieveStandardIds.length > 0) {
      const res = await pear.standards.listStandards({
        standardIds: retrieveStandardIds,
      });

      res.map((standard: Standard) => {
        standards.set(_standardKey(standard.id), standard);
      });

      setState((state: IPearState) => {
        return {
          ...state,
          standards,
        };
      });
    }

    const standardKeys = [
      ...new Set(standardIds.map((standardId) => _standardKey(standardId))),
    ];

    return standardKeys
      .map((standardKey) => standards.get(standardKey))
      .filter((a) => !!a) as Array<Standard>;
  };

  const populatePracticeSetStandards = async (practiceSet: IPracticeSet) => {
    if (!pearStandardsEnabled) return;

    practiceSet.pear_standards = await listStandards(
      practiceSet.practice_set_item_pear_standard_ids || []
    );
  };

  const populatePracticeSetsStandards = async (
    practiceSets: Array<IPracticeSet>
  ) => {
    if (!pearStandardsEnabled) return;

    const pearStandards = await listStandards(
      practiceSets.flatMap(
        (practiceSet) => practiceSet.practice_set_item_pear_standard_ids || []
      )
    );
    practiceSets.forEach((practiceSet) => {
      practiceSet.pear_standards = pearStandards.filter((pearStandard) =>
        (practiceSet.practice_set_item_pear_standard_ids || []).includes(
          pearStandard.id
        )
      );
    });
  };

  const populatePracticeSetItemStandards = async (
    practiceSetItem: IPracticeSetItem
  ) => {
    if (!pearStandardsEnabled) return;

    practiceSetItem.pear_standards = await listStandards(
      practiceSetItem.standards.map((s) => s.pear_standard_id)
    );
  };

  const populatePracticeSetItemsStandards = async (
    practiceSetItems: Array<IPracticeSetItem>
  ) => {
    if (!pearStandardsEnabled) return;

    const pearStandards = await listStandards(
      practiceSetItems.flatMap((practiceSetItem) =>
        practiceSetItem.standards.map((s) => s.pear_standard_id)
      )
    );

    practiceSetItems.forEach((practiceSetItem) => {
      practiceSetItem.pear_standards = pearStandards.filter((pearStandard) =>
        practiceSetItem.standards
          .map((standard) => standard.pear_standard_id)
          .includes(pearStandard.id)
      );
    });
  };

  const openStandardsModal = async (
    options: OpenStandardsModalOptions
  ): Promise<OpenStandardsModalResponse | undefined> => {
    setState((state) => {
      return {
        ...state,
        isStandardsModalOpen: true,
      };
    });

    // Use last returned search params unless provided by the caller
    options.searchParams =
      options.searchParams || state.standardsModalSearchParams || {};

    // Use the user's region unless a region is already selected
    options.searchParams.region =
      options.searchParams.region || authUser?.region;

    const res = await pear.standards.openStandardsModal(options);

    setState((state) => {
      return {
        ...state,
        isStandardsModalOpen: false,
        standardsModalSearchParams: res
          ? res.searchParams
          : state.standardsModalSearchParams,
      };
    });

    return res;
  };

  const value = {
    isStandardsModalOpen: state.isStandardsModalOpen,
    listStandards,
    openStandardsModal,
    populatePracticeSetItemStandards,
    populatePracticeSetItemsStandards,
    populatePracticeSetStandards,
    populatePracticeSetsStandards,
  };

  return <PearContext.Provider value={value}>{children}</PearContext.Provider>;
};
