import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { CreateQuestionFooter } from "adminComponents/molecules/CreateQuestionFooter";
import { FilterPracticeSet } from "adminComponents/molecules/FilterPracticeSet";
import { SearchInput } from "adminComponents/molecules/SearchInput";
import { StandardsPickerCard } from "adminComponents/molecules/StandardsPickerCard";
import { pxToRem } from "adminComponents/utils/pxToRem";
import { useErrorToast } from "adminComponents/utils/toast";
import { useAuth } from "links/lib/features/auth";
import { useFetchStandardCollectionsOnce } from "links/lib/features/standardCollections";
import { IStandard } from "links/lib/types";

import { CreateQuestionStandardsTabProps, CreateStandardTabForm } from ".";

const initialValue: CreateStandardTabForm = {
  standards: [],
  pearStandards: [],
};

export const CreateQuestionStandardsTabLegacy = React.forwardRef<
  HTMLDivElement,
  CreateQuestionStandardsTabProps
>(
  (
    /* eslint-disable react/prop-types */
    {
      handleSaveAndClose,
      handleChangeForm,
      states,
      regionFilter,
      grades,
      gradeLevelIdFilter,
      subjects,
      subjectIdFilter,
      standardCollectionIdFilter = "",
      handleChangeFilter,
      standardOptions,
      defaultValue,
      canSubmit,
      isSubmitting,
      isLoadingStandards,
      handleSearch,
      handleDirty,
    }: CreateQuestionStandardsTabProps,
    ref
  ) => {
    const { authUser } = useAuth();
    const { t } = useTranslation("admin", { useSuspense: false });

    const standardCollectionFetch = useFetchStandardCollectionsOnce();
    const prevRegionFilter = usePrevious(regionFilter);
    const prevGradeLevelIdFilter = usePrevious(gradeLevelIdFilter);
    const prevSubjectIdFilter = usePrevious(subjectIdFilter);
    useEffect(() => {
      if (!regionFilter) return;
      if (
        regionFilter !== prevRegionFilter ||
        gradeLevelIdFilter !== prevGradeLevelIdFilter ||
        subjectIdFilter !== prevSubjectIdFilter
      ) {
        standardCollectionFetch.execute({
          limit: 50,
          offset: 0,
          country: authUser?.country || "US",
          region: regionFilter,
          grade_level_id: gradeLevelIdFilter,
          subject_id: subjectIdFilter,
        });
      }
    }, [
      regionFilter,
      gradeLevelIdFilter,
      subjectIdFilter,
      prevRegionFilter,
      prevGradeLevelIdFilter,
      prevSubjectIdFilter,
      standardCollectionFetch,
      authUser,
    ]);
    useErrorToast(standardCollectionFetch.error);

    const standardCollectionOptions = useMemo(() => {
      const options =
        regionFilter === ""
          ? []
          : standardCollectionFetch.data?.standard_collections.map(
              (standardCollection) => {
                return {
                  value: standardCollection.id,
                  label: standardCollection.name,
                };
              }
            ) ?? [];

      return [
        {
          label: t("createQuestion.labelAnyStandardCollection"),
          value: "",
        },
      ].concat(options);
    }, [t, regionFilter, standardCollectionFetch.data]);

    const {
      handleSubmit,
      setValue,
      watch,
      getValues,
      control,
      formState: { isDirty },
    } = useForm<CreateStandardTabForm>({
      defaultValues: defaultValue ?? initialValue,
      mode: "onChange",
    });

    const { append, remove } = useFieldArray({
      control,
      name: "standards",
    });

    useEffect(() => {
      if (isDirty && handleDirty) handleDirty();
    }, [isDirty, handleDirty]);

    const _handleSubmit = (data: CreateStandardTabForm) => {
      handleSaveAndClose(data);
    };

    const selectedStandards = watch("standards");
    watch(() => {
      handleChangeForm(getValues());
    });

    const handleCheckStandard = (checked: boolean, value: IStandard) => {
      const index = selectedStandards.findIndex((s) => s.id === value.id);

      if (checked && index === -1) {
        append(value);
      } else if (index !== -1 && !checked) {
        handleRemoveStandard(index);
      }
    };

    const handleRemoveStandard = (index: number) => {
      remove(index);
    };

    return (
      <form onSubmit={handleSubmit(_handleSubmit)}>
        <SimpleGrid
          ref={ref}
          py={pxToRem(40)}
          px={[
            "admin.flyout.mobileXPadding",
            null,
            "admin.flyout.desktopXPadding",
          ]}
          gap={pxToRem(40)}
        >
          <SimpleGrid gap={pxToRem(24)}>
            <Text as="h4" variant="adminP1">
              {t("createQuestion.standardsTitle")}
            </Text>
            <Text as="h4" variant="adminP1">
              {t("createQuestion.standardsTitle2")}
            </Text>
            <Box>
              <SearchInput
                placeholder={t("common.search")}
                handleSearch={handleSearch}
                buttonText={t("common.search")}
              />
            </Box>
            <FilterPracticeSet
              grades={grades}
              gradeLevelIdFilter={gradeLevelIdFilter}
              states={states}
              regionFilter={regionFilter}
              subjects={subjects}
              subjectIdFilter={subjectIdFilter}
              handleChangeFilter={handleChangeFilter}
              standardCollections={standardCollectionOptions}
              standardCollectionIdFilter={standardCollectionIdFilter}
              showStandardsCollectionFilter
            />
            {selectedStandards.length > 0 && (
              <SimpleGrid gap={pxToRem(12)} columns={3} spacing={pxToRem(20)}>
                {selectedStandards.map((standard, index) => (
                  <Tag
                    variant="adminSolid"
                    key={standard.id}
                    rightIcon="close"
                    colorScheme="default"
                    handleClickRightIcon={() => handleRemoveStandard(index)}
                    tooltip={standard.root_label || standard.label}
                    maxW="fit-content"
                  >
                    {standard.root_label || standard.label}
                  </Tag>
                ))}
                <Button
                  variant="adminTextButtonMedium"
                  onClick={() => setValue("standards", [])}
                  marginLeft="auto"
                >
                  {t("common.clearAll")}
                </Button>
              </SimpleGrid>
            )}
          </SimpleGrid>
          {isLoadingStandards && <LoadingSpinner />}
          <SimpleGrid gap={pxToRem(12)}>
            {!isLoadingStandards && !standardOptions.length && (
              <Text>{t("createQuestion.searchStandardsNoResults")}</Text>
            )}
            {standardOptions.map((standard: IStandard) => (
              <StandardsPickerCard
                key={standard.id}
                checked={selectedStandards.some(
                  (attachedStandard) => attachedStandard.id === standard.id
                )}
                standard={standard}
                handleChange={(checked) =>
                  handleCheckStandard(checked, standard)
                }
              />
            ))}
          </SimpleGrid>
          <CreateQuestionFooter
            canSubmit={canSubmit}
            isSubmitting={isSubmitting}
            hideHigherOrderThinking
            handleSaveAndClose={handleSubmit(_handleSubmit)}
          />
        </SimpleGrid>
      </form>
    );
  }
);

CreateQuestionStandardsTabLegacy.displayName =
  "CreateQuestionStandardsTabLegacy";
