import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "adminComponents/atoms/Heading";
import {
  IconCTAButtonWrapper as CloseButtonWrapper,
  IconCTAButton,
} from "adminComponents/atoms/IconCTAButton";
import { Text } from "adminComponents/atoms/Text";

export interface IProps extends DrawerProps {
  title?: string;
  subtitle?: string;
  showBack?: boolean;
  showClose?: boolean;
  footerContent?: React.ReactNode;
  onBack?: () => void;
}

export const AdminFlyout: React.FC<IProps> = ({
  title,
  subtitle,
  children,
  variant = "adminFlyoutDefault",
  showBack = false,
  showClose = true,
  footerContent,
  onBack,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation("admin", { useSuspense: false });
  const styles = useMultiStyleConfig("AdminFlyout", {});
  return (
    <Drawer
      {...rest}
      size={rest.size ?? "lg"}
      variant={variant}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        {showBack && (
          <CloseButtonWrapper sx={styles.backButton}>
            <IconCTAButton
              sx={styles.backIcon}
              ariaLabel={t("common.ariaBackToPreviousScreen")}
              boxSize={24}
              icon="keyboard_arrow_left"
              iconSize={20}
              onClick={onBack}
            />
            <Text variant="adminP3">{t("common.back")}</Text>
          </CloseButtonWrapper>
        )}
        {showClose && (
          <CloseButtonWrapper sx={styles.closeButton}>
            <IconCTAButton ariaLabel={t("common.close")} onClick={onClose} />
          </CloseButtonWrapper>
        )}
        {/* Explicitly passing empty string should render an empty header */}
        {title !== undefined && (
          <DrawerHeader>
            <Heading as="h2" variant="adminH3">
              {title}
            </Heading>
            {subtitle && (
              <Text variant="adminP2" sx={styles.subtitle}>
                {subtitle}
              </Text>
            )}
          </DrawerHeader>
        )}
        <DrawerBody>{children}</DrawerBody>
        {!!footerContent && (
          <DrawerFooter sx={styles.footer}>{footerContent}</DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};
