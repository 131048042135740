import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";

export const usePearStandardsEnabled = (): boolean => {
  const { isFeatureEnabled, authUser } = useAuth();

  // Pear Standards cannot be enabled for students until Pear Rostering
  // is implemented and students have Pear Users.
  if (authUser?.role === UserRole.Student) {
    return false;
  }

  return isFeatureEnabled("playtime.enable_pear_standards");
};
